import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import {
  Box,
  Container,
  Grid,
  Typography,
  Hidden,
  Link,
  Button,
  Toolbar,
  makeStyles
} from "@material-ui/core";
import useAuth from "src/hooks/useAuth";
import Image from "src/components/Image";
// import Toolbar from "@fullcalendar/core/Toolbar";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper, // dark -> light
    // paddingTop: 200,
    // paddingBottom: 200,
    magin : 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  heading: {
    backgroundColor: theme.palette.background.paper, 
  },
  button: {
    border: '1px solid #3855B3',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: theme.palette.primary.main
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  mid: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 60,
    paddingBottom: 60
  },
  midHeading: {
    display: 'flex',
    alignItems: 'center',
  },
  bottom: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 130,
    paddingBottom: 130
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'f7f8fc',
    color: '#221e1f',
    paddingLeft:150,
    paddingRight: 150,
    paddingTop: 75,
    paddingBottom: 75,

    [theme.breakpoints.down("md")]: {
      paddingLeft:50,
      paddingRight: 50
    }
  },
  headingBoxL: {
    paddingTop: 62.5,
    paddingBottom: 62.5,
    paddingLeft: 50,
    paddingRight: 50,
  },
  headingBoxR: {
    paddingTop: 62.5,
    paddingBottom: 62.5,
    paddingLeft: 50,
    paddingRight: 50,
  },
  headingButton: {
    fontFamily: 'Noto Sans CJK KR',
    fontSize: '14px',
    fontWeight: 700
  },
  midImg: {
    // marginTop: 41,
    // backgroundColor: theme.palette.background.dark,
    marginBottom: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  midBox1: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    dispaly: 'flex',
    justifyContent: 'center',
    paddingBottom: 62.5,
    paddingLeft: 50,
    paddingRight: 50,
  },
  midBox2: {
    
    backgroundColor: theme.palette.background.dark,
    margin: 8,
    padding: '41px 50px'
  },
  // midBox3: {
  //   backgroundColor: '#fff',
  //   margin: 8,
  //   padding: '41px 74px'
  // },
  bottomBox1: {
    width: '100%',
    dispaly: 'flex',
    justifyContent: 'center',
    paddingBottom: 62.5,
    paddingLeft: 50,
    paddingRight: 50,
  },
  bottomBox2: {
    // width: '100%',
    dispaly: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // paddingBottom: 62.5,
    // paddingLeft: 240,
    // paddingRight: 240,
  },
  bottomBox3: {
    backgroundColor: 'rgba(56, 85, 179, 0.04)',
    width: '100%',
    dispaly: 'flex',
    justifyContent: 'center',
    padding: 50,
    paddingLeft: 240,
    paddingRight: 240,
    [theme.breakpoints.down("md")]: {
      paddingLeft:20,
      paddingRight: 20
    }
  },
  headingBold1: {
    //styleName: Heading / Bold / 1
    fontFamily: 'Roboto',
    fontSize: 40,
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 38,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.third
  },
  headingBold4: {
    //styleName: Heading / Bold / 1
    fontFamily: 'Roboto',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 500,
    // lineHeight: 38,
    letterSpacing: 0,
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  midBold1: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '38px',
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.third,
    marginRight: '10px',
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: '22px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  midBold1_1: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginRight: '10px',
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: '14px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  
  midBold2: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 38,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.third,
    marginLeft: 5,
    marginRight: 5

  },
  midBold3: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    // lineHeight: 38,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 25,
    // marginBottom: 41
  },
  bottomBold1: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Montserrat',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 38,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: '28px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  bottomBold1_1: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Montserrat',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 38,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: '28px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  bottomBold2: {
    //styleName: Heading / Bold / 1
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 38,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: '20px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  bottomNormal1: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    // lineHeight: 38,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerImgBox: {
    // marginTop: 41,
    marginBottom: 25,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  footerImg: {
    color: theme.palette.text.secondary,
  },
  footerBold1: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Montserrat',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '33.6px',
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: '28px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  footerBold1_1: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '21.6px',
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: '18px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  footerBold2: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Montserrat',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 38,
    letterSpacing: 0,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 8,
    [theme.breakpoints.down("md")]: {
      fontSize: '14px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  footerBold3: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 38,
    letterSpacing: 0,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: '14px',
      marginLeft: 5,
      marginRight: 5,
    }
  },
  footerNormal1: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Noto Sans CJK KR',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    // lineHeight: 38,
    letterSpacing: 0,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 8
  },
  footerNormal2: {
    //styleName: Heading / Bold / 1
    display: 'inlineBlock',
    fontFamily: 'Noto Sans CJK KR',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    // lineHeight: 38,
    letterSpacing: 0,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: 5,
    marginRight: 5
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  symbol: {
    perspectiveOrigin: "left center",
    "& > img": {
      maxWidth: "90%",
      height: "auto",
    }
  },

  image: {
    perspectiveOrigin: "left center",
    transformStyle: "preserve-3d",
    perspective: 1500,
    "& > img": {
      maxWidth: "90%",
      height: "auto",
      transform: "rotateY(-35deg) rotateX(15deg)",
      backfaceVisibility: "hidden",
      boxShadow: theme.shadows[16]
    }
  },

  shape: {
    position: "absolute",
    top: 0,
    left: 0,
    "& > img": {
      maxWidth: "90%",
      height: "auto"
    }
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();
  const { user, logout } = useAuth();

  useEffect(()=> {
    if(user !== null){
      logout(user.uuid); // 랜딩 페이지에서는 항상 로그아웃 처리를 해준다.
    }
  },[])

  return (
    <Box className={clsx(classes.root, className)} style={{padding : '0px', margin: '0px'}} {...rest}>
      <Container disableGutters maxWidth="xl">
        {/* 상단 부분 */}
        <Grid container className={classes.heading} spacing={0} >
          {/* 좌측 텍스트 부분 */}
          <Grid item xs={12} md={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
              className={classes.headingBoxL}
            >
              <Typography className={classes.headingBold1}>
                CodeMeter Monitor
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
            <Link
              className={classes.link}
              component={RouterLink}
              to="/app"
              underline="none"
              variant="body2"
            >
              <Button className={classes.button} >
                SIGN UP
              </Button>
            </Link>
            </Box>
          </Grid>
          {/* 우측 이미지 부분 */}
          {/* <Grid item xs={12} md={7}>
            <Box position="relative">
              <div className={classes.headingBoxR}>
                <img alt="AD Eyes" src="/static/home/heading.svg" style={{height:'auto', width:'100%'}}/>
              </div>
            </Box>
          </Grid> */}
          
        </Grid>
        {/* 가운데 부분 */}
        <Grid container className={classes.mid}>
          {/* 문구 */}
          <Grid container
            // display="flex"
            // flexDirection="column"
            // justifyContent="center"
            // height="100%"
            className={classes.midBox1}
          > 
          <Box>
            <Typography className={classes.midBold1}>CodeMeter의 주요 기능</Typography>
          </Box>
          </Grid>
          {/* 이미지 설명 */}
          <Grid item xs={12} md={3} className={classes.midBox2}>
            <Box >
              <Box className={classes.midImg}>
                <Image folder="home" filename="sensor_interlock" width="142px" height="89px" />
              </Box>
              <Typography className={classes.midBold2}>CodeMeter와 AP와의 통신</Typography>
              <Typography className={classes.midBold3}>
                인터넷을 통한 측정정보 저장 및 Web-app에서 측정정보 확인이 가능합니다.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} className={classes.midBox2}>
            <Box >
              <Box className={classes.midImg}>
                <Image folder="home" filename="breeding_info" width="133.5px" height="89px" />
              </Box>
              
              <Typography className={classes.midBold2}>측정 정보의 활용</Typography>
              <Typography className={classes.midBold3}>
                측정 정보를 다운로드하여 연구 등의 다양한 목적에 활용할 수 있습니다.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} className={classes.midBox2}>
            <Box >
              <Box className={classes.midImg}>
                <Image folder="home" filename="breeding_statics" width="139px" height="89px" />
              </Box>
              <Typography className={classes.midBold2}>측정 정보 통계데이터 확인</Typography>
              <Typography className={classes.midBold3}>
                측정 정보의 기간별 통계 데이터를 제공합니다.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* 하단 부분 */}
        {/* <Grid container className={classes.bottom}> */}
          {/* 문구 1 */}
          {/* <Grid container className={classes.bottomBox1}>
            <div className={classes.bottomBold1}>
              더 많은 정보가
            </div>
            <div className={classes.bottomBold1}>
              필요하시나요?
            </div>
          </Grid> */}
          {/* 문구 2 */}
          {/* <Grid className={classes.bottomBox1}>
            <Typography className={classes.bottomNormal1}>
            Aqua Development Ltd.(AD)는 
            </Typography>
            <Typography className={classes.bottomNormal1}>
            "AD Eyes" 기술을 사용하여 최고 품질의 유기농 새우를 생산하는 혁신적인 양식 회사입니다.
            </Typography>
          </Grid> */}
          {/* 연락처 정보 */}
          {/* <Grid className={classes.bottomBox2}>
            <Box className={classes.bottomBox3}>
              <Grid container className={classes.bottomBold2} style={{marginBottom: '10px'}}>
                <Grid item xs={12} md={1} className={classes.itemCenter}>
                  <div className={classes.itemCenter} style={{marginRight: '12px'}}>
                    <img alt="call" src="/static/home/call.svg" style={{height:'21px', width:'21px'}}/>
                  </div>
                </Grid>
                <Grid className={classes.itemCenter}>
                  <div>
                    <span >+82 7088361670</span>
                  </div>
                </Grid>
              </Grid>
              <Grid container className={classes.bottomBold2}>
                <Grid item xs={12} md={1} className={classes.itemCenter}>
                  <div className={classes.itemCenter} style={{marginRight: '12px'}}>
                    <img alt="mail" src="/static/home/mail.svg" style={{height:'26px', width:'26px'}}/>
                  </div>
                </Grid>
                <Grid >
                  <div>
                    <span>contact@aqua-development.com</span>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid> */}
        {/* footer 부분 */}
        <Grid container className={classes.footer}>
          <Grid container>
            <Grid container>
              <Grid item xs={12}>
              <Box className={classes.footerImgBox}>
                <Image folder="home" filename="SOTHECODE_CI_B_horizontal" width="270px" color="#374151"/>
              </Box>
              </Grid>
              <Grid container>
                {/* 항목 이름은 md 이하에서 감춤 */}
                <Hidden mdDown>
                  <Grid itemmd={2}>
                    <Typography className={classes.footerBold2}>
                      Korea
                    </Typography>
                    <Typography className={classes.footerBold2}>
                      Korea(EN)
                    </Typography>
                    <Typography className={classes.footerBold2}>
                      Email
                    </Typography>
                    <Typography className={classes.footerBold2}>
                      Tel
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={12} md={10}>
                  <Typography className={classes.footerNormal1}>
                    전라북도 전주시 덕진구 만성북로 21-26 전북콘텐츠기업지원센터 401호 (우)54858
                  </Typography>
                  <Typography className={classes.footerNormal1}>
                    401ho, 21-26, Manseongbuk-ro, Deokjin-gu, Jeonju-si, Jeollabuk-do, Republic of Korea
                  </Typography>
                  <Typography className={classes.footerNormal1}>
                    sothecode@sothecode.com
                  </Typography>
                  <Typography className={classes.footerNormal1}>
                    +82 70-4077-0327
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              
              <Grid item xs={12} md={12}>
                <Box 
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  style={{marginTop: '28px' }}
                >
                  <a 
                    href="https://www.sothecode.com/_files/ugd/fe33b1_ec7879710be140219bff45899fdd5724.pdf"
                    style={{color:'black', textDecoration: 'none'}}
                    target="_blank"
                  >
                    <Typography className={classes.footerBold3} >개인정보처리방침</Typography>
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box 
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  style={{marginTop: '28px' }}>
                    <Typography className={classes.footerNormal2} >COPYRIGHT © SOTHECODE CORP. ALL RIGHTS RESERVED.</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container >
    </Box>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;

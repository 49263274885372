import _ from 'lodash';
import {
  colors,
  createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[300]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: '#4AC1E0',
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: '#F5F5F5',
        profilePaper: '#FFFFFF',
        mobilePaper: '#FFFFFF',
        mainChat: '#F3F4F6',
        chat: '#F3F4F6',
        chatSearch: '#FFFFFF',
        threadList: colors.common.white,
        chatList: colors.common.white,
        mainMessageHead: colors.common.white,
        messageHead: colors.common.white,
        mainMessageList:colors.common.white,
        messageList: colors.common.white,
        messageItem: '#F3F4F6',
        mainChatComponent: colors.common.white,
        chatComponent: colors.common.white,
        chart: colors.common.white,
        eachChart: colors.common.white,
        report: colors.common.white,
        themeBox: '#F3F4F6',
        menuButton: 'rgba(74, 193, 224, 0.1)',
        modifyButton: '#002D74',
        modifyButtonHover: '#00173C',
        input: 'rgba(244,246,248,0.3)',
        modalRoot: '#FFFFFF',
        modalCard: '#F3F4F6',
        modalRootMobile: '#FFFFFF',
        modalCardMobile: '#F3F4F6',
        deviceCancleButton: '#AAABBF',
        deviceCancleButtonHover: '#8A8BA8',
        deviceConfirmButton: '#002D74',
        deviceConfirmButtonHover: '#001c49',
        selectField: '#FFFFFF',
        threadListNullBox:'#FFFFFF',
        threadListNullLogoBox: '#F3F4F6',
        sensorIsUseBox:'#FFFFFF',
        sensorSettingBox:'#FFFFFF',
        sensorValueBox:'#FFFFFF',

      },
      primary: {
        main: colors.indigo[600]
      },
      secondary: {
        main: '#5850EC'
      },
      third: {
        main: '#4BB9D6'
      },
      border: {
        main: colors.common.white,
        dark: '#D6D6D6', // #EBEFF2
        chatSearch: '#EAEAEA',
        mobile: '#FFFFFF',
        select: '#EAEAEA',
        threadList: '#EAEAEA',
        messageComposer: '#D6D6D6',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        third: "rgba(24, 24, 25, 0.6)",
        link: colors.indigo[600],
        chat: '#757579',
        chatSearch: '#858EA1',
        required: '#EF4444',
        selectLabel:'#858EA1',
        selectContent: '#858EA1',
        noStatics:'#757579',
        threadTime: '#757579',
        threadContent: '#858EA1',
        backIcon: '#757579',
        forwardIcon: '#858EA1',
        chatFileIcon: '#C2CFE0',
        themeIcon: '#858EA1',
        moreIcon: '#C2CFE0',
        searchContent: '#C5C5C5',
        messageTime: '#90A0B7',
        threadListNull: '#C5C5C5',
        sensortTitle: '#858EA1',
      },

    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: '#4AC1E0',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.15)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#353A55',
        dark: '#424760',
        paper: '#2A2B41',
        profilePaper: '#373A55',
        mobilePaper: '#2A2B41',
        mainChat: '#444760',
        chat: '#373A55',
        chatSearch: '#373A55',
        chatList: '#373A55',
        threadList: '#373A55',
        mainMessageHead: '#444760',
        messageHead: '#373A55',
        mainMessageList:'#444760',
        messageList: '#373A55',
        messageItem: '#505476',
        mainChatComponent: '#444760',
        chatComponent: '#373A55', 
        chart: '#2A2B40',
        eachChart: '#424760',
        report: '#adb0bb',
        themeBox: '#292B41',
        menuButton: '#2A2B41',
        modifyButton: '#4BB9D6',
        modifyButtonHover: '#0DA3CA',
        input: 'rgba(244,246,248,0.3)',
        modalRoot: '#292B41',
        modalCard: '#373A55',
        modalRootMobile: '#373A55',
        modalCardMobile: '#292B41',
        deviceCancleButton: '#858EA1',
        deviceCancleButtonHover: '#6C768A',
        deviceConfirmButton:'#4AC1E0',
        deviceConfirmButtonHover: '#0DA3CA',
        selectField: '#505476',
        threadListNullBox:'#373A55',
        threadListNullLogoBox: '#51557B',
        sensorIsUseBox:'#373A55',
        sensorSettingBox:'#373A55',
        sensorValueBox: '#373A55',
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      third: {
        main: '#4BB9D6'
      },
      border: {
        main: '#505476',
        dark: '#505476',
        chatSearch: '#505476',
        mobile: '#2A2B41',
        select: '#51557B',
        threadList: '#505476',
        messageComposer: '#505476',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#adb0bb',
        third: colors.common.white,
        link: '#4BB9D6',
        chat: colors.common.white,
        chatSearch: '#AAABBF', 
        required: '#EF4444',
        selectLabel:'#AAABBF',
        selectContent: '#FFFFFF',
        noStatics: '#AAABBF',
        threadTime: '#858EA1',
        threadContent:'#FFFFFF',
        backIcon: '#AAABBF',
        forwardIcon: '#FFFFFF',
        chatFileIcon: '#AAABBF',
        themeIcon: '#FFFFFF',
        moreIcon: '#FFFFFF',
        searchContent: '#AAABBF',
        messageTime: '#AAABBF',
        threadListNull: '#51557B',
        sensortTitle: '#AAABBF',
      },

    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
        profilePaper: '#FFFFFF', 
        mobilePaper: '#FFFFFF',
        mainChat: '#C2C8CE',
        chat: '#C2C8CE',
        chatSearch: '#FFFFFF',
        threadList: colors.common.white,
        chatList: colors.common.white,
        mainMessageHead: colors.common.white,
        messageHead: '#424760',
        mainMessageList: colors.common.white,
        messageList: colors.common.white,
        messageItem: '#D6D6D6',
        mainChatComponent: colors.common.white,
        chatComponent: colors.common.white,
        chart: '#f4f6f8',
        eachChart: colors.common.white,
        report: '#adb0bb',
        themeBox: '#F3F4F6',
        menuButton: 'rgba(74, 193, 224, 0.1)',
        modifyButton: '#4BB9D6',
        modifyButtonHover: '#0DA3CA',
        input: 'rgba(244,246,248,0.3)',
        modalRoot: '#FFFFFF',
        modalCard: '#F3F4F6',
        modalRootMobile: '#FFFFFF',
        modalCardMobile: '#F3F4F6',
        deviceCancleButton: '#AAABBF',
        deviceCancleButtonHover: '#8A8BA8',
        deviceConfirmButton: '#002D74',
        deviceConfirmButtonHover: '#001c49',
        selectField: '#505476',
        threadListNullBox:'#FFFFFF',
        threadListNullLogoBox: '#F3F4F6',
        sensorIsUseBox:'#FFFFFF',
        sensorSettingBox:'#FFFFFF',
        sensorValueBox:'#FFFFFF',
        sensortTitle: '#AAABBF',
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      third: {
        main: 'rgba(75,185,214, 1)',
      },
      border: {
        main: colors.common.white,
        dark: '#D6D6D6',
        chatSearch: '#505476',
        mobile: '#D6D6D6',
        select: '#EAEAEA',
        threadList: '#EAEAEA',
        messageComposer: '#D6D6D6',
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4',
        third: "rgba(24, 24, 25, 0.6)",
        link: '#4BB9D6',
        chat: '#757579',
        chatSearch: '#EAEAEA',
        required: '#EF4444',
        selectLabel:'#757579',
        selectContent: '#757579',
        noStatics: '#757579',
        threadTime: '#858EA1',
        threadContent: '#858EA1',
        backIcon: '#AAABBF',
        forwardIcon: '#858EA1',
        chatFileIcon: '#C2CFE0',
        themeIcon: '#858EA1',
        moreIcon: '#C2CFE0',
        searchContent: '#C5C5C5',
        messageTime: '#90A0B7',
        threadListNull: '#C5C5C5',
      },

    },
    shadows: strongShadows
  }
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
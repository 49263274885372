import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Hidden,
  Link,
  Button,
  Toolbar,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    flexDirection:'column',
    
  },
  topTextA: {
    fontFamily: 'Montserrat',
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '33.6px',
    color: '#FFFFFF',
    letterSpacing: 0,
    textAlign: 'center'
  },
  topTextB: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFFFFF',
    lineHeight: '21.6px',
    letterSpacing: 0,
    textAlign: 'center',
    // marginLeft: 10,
    // paddingTop: 8
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  
  return (
    <Box className={classes.logoContainer}>
      <Typography className={classes.topTextA}>
        AD
      </ Typography>

      <Typography className={classes.topTextB}>
        Eyes
      </ Typography>
    </Box>
  );
}

export default Logo;

import React, { useState, setState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { 
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  makeStyles 

} from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar/index';
import { useDispatch, useSelector } from 'src/store';
import { 
  getParticipants,
  getThreads,
  getThread,
  removeRecipient,
  updateThread,
  updateActiveThreadId,
} from 'src/slices/chat';
import { updateMenu } from 'src/slices/menu';
import {SocketContext} from 'src/contexts/SocketContext';
import useAuth from 'src/hooks/useAuth';
import { BiLogIn } from "react-icons/bi";
import CloseIcon from '@material-ui/icons/Close';
import Typography from 'src/theme/typography';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection:'row',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down('xs')] : {
      background: theme.palette.background.mobilePaper,
    }
  },
  scrollBox: {
    // overflow: 'auto',
  },
  mainContent: {
    width: 'calc( 100% - 68px )', // calc( 100% - 256px )
    height: '100%',
    // overflow: 'auto',
  },
  wrapperGrid: {
    width:'100%',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'auto',
    // paddingTop: 64,
    height:'100%',
    width:'100%',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: 68
    },
    [theme.breakpoints.down('sm')]: {
      // marginLeft: 233
    }
  },
  contentContainer: {
    width:'100%',
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',
  },
  nav: {
    display: 'flex',
    flex: '1 1 auto',
    minHeight: 500,
    averflow: 'auto',
  },
  topbar: {
    boxShadow: 'none !important'
  },
  content: {
    width:'100%',
    flex: '1 1 auto',
    height: '100%',
    // overflow: 'auto',
  },
  actionButton: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 700,
    style: 'normal',
    color:"#4BB9D6",
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const chat = useSelector((state) => state.chat); // store를 구독한다.
  const menu = useSelector((state) => state.menu.value); // store 구독
  const [state, setState] = React.useState({
    isMobileNavOpen: false,
    selectedMenu: "Dashboard"
  });
  const [menuState, setMenuState] = useState({
    selectedMenu: menu.selectedMenu,
    path: menu.path,
    lastMenu: menu.lastMenu,
    lastMenuPath: menu.lastMenuPath,
  })
  const { user } =useAuth(); 
  const dispatch = useDispatch();
  const history = useHistory();
  const socket = useContext(SocketContext);
  const currentPath = window.location.pathname;
  const currentThreadKey =  currentPath.split('/').pop(); // 마지막 요소를 가져온다.
  const isMountedRef = useIsMountedRef();

  const [snackBarstate, setSnackBarState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
    message: '',
    threadKey: '',
  });

  const { open, vertical, horizontal, message } = snackBarstate;

  const handleClose = () => {
    setSnackBarState({ ...snackBarstate, open: false });
  };

  const handleClickEnter = () => {
    let chatUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/chat/${snackBarstate.threadKey}`;
      // console.log('recChatUrl',chatUrl);
      window.location.href = chatUrl; // 새로생성된 채팅방을 로드한다.
  }

  useEffect(() => {
   
    // 새로운 chat 메세지를 받는다.
    socket.on('recChat', (newMessage) => {
      let receipientsCount = newMessage.receipientsCount;
      // console.log('recChat', newMessage);
      const host = newMessage.host;
      // let recMessage = data.comment;
      // setRecMessage(recMessage);
      // count++;
      // console.log("recChat", count)
      // let newThread = newMessage.newThread ;
      // ----------------------------
      // 쓰레드를 새로 읽어오는 방식
      // ----------------------------
      
      // let message = newThread.messages;
      // const newThreadKey = newThread.threadId;
      // // console.log("newThread",message);
      // // console.log("newThreadKey",newThreadKey);
      // // const messageId = message[0]['messageid'];
      // // console.log("messageId",messageId);
      // // setNewMessageId(messageId);
      // // setThreadKeyState(newThreadKey);
      // dispatch(getThread(newThreadKey));

      //-----------------------------
      // store에 요소를 추가하는 방식
      //-----------------------------
      // 이슈: hook을 이용하지 않으면 과도한 리렌더링 현상 발생
      // let responseThread = newMessage.newThread;
    
      // console.log('newMessage.receipientsCount',receipientsCount);
      
      if(receipientsCount == 0 && host == user.name){ // 채팅방 추가 버튼을 통해 메세지를 보낸 경우
        dispatch(updateActiveThreadId(newMessage.threadKey));
        
        let chatUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/chat/${newMessage.threadKey}`;
        // console.log('recChatUrl',chatUrl);
        // history.push(`/app/chat/${newMessage.threadKey}`);
        window.location.href = chatUrl; // 새로생성된 채팅방을 로드한다.
        // dispatch(getThreads(user.farmname));
        setTimeout(() => dispatch(getThreads(user.farmname)), 1000);
      } else if( receipientsCount > 0){ // 상대방을 선택하여 메세지를 보낸 경우
        dispatch(updateActiveThreadId(newMessage.threadKey));
        
        let chatUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/chat/${newMessage.threadKey}`;
        // console.log('recChatUrl',chatUrl);
        // history.push(`/app/chat/${newMessage.threadKey}`);
        window.location.href = chatUrl; // 새로생성된 채팅방을 로드한다.
        // dispatch(getThreads(user.farmname));
        setTimeout(() => dispatch(getThreads(user.farmname)), 1000);
      } else { // 채팅방에서 메세지를 전송한 경우
        // console.log('3', newMessage)
        let newThread = { // thread를 만들어준다.
          thread: newMessage.newThread['messages'][0],
        };
        // console.log("newThread.thread",newThread.thread);
        setTimeout(() => dispatch(updateThread(newThread)), 1000);
      }
    });

    socket.on('joined', (data) => {
      // console.log(data.name+'님이 입장했습니다.');
    });

    socket.on('leaved', (data) => {
      // console.log(data.threadKey+' 채팅방에서 나왔습니다.');
    });

    // 블럭된 유저정보를 받아 화면에 처리한다.
    socket.on('recBlockUser', (data) => {
      dispatch(getThreads(user.farmname));
      
      // 접속된 클라이언트가 블럭된 유저인 경우 로비로 이동한다.
      if(data.participantUuid === user.uuid){
        history.push('/app/chat/new');
        // dispatch(updateActiveThreadId(''));
        // setActiveThreadState({
        //   ...activeThreadState,
        //   activeThreadId : '',
        // });
        setSnackBarState({
          ...snackBarstate,
          open: true,
          vertical: 'top',
          horizontal: 'center',
          message: `채팅방에서 차단되었습니다.`,
        })

      } else { // 블럭 유저가 아닌 경우
        setSnackBarState({
          ...snackBarstate,
          vertical: 'top',
          horizontal: 'center',
          open: true,
          message: `${data.message}`
        })
      }
      
      setTimeout(() =>
        setSnackBarState({
          ...snackBarstate,
          open: false,
          vertical: 'top',
          horizontal: 'center',
          message: ``
        })
      ,10000) // 10초 후에 snackBar state를 초기화 한다.
      // console.log('recBlockUser',data.message);
    })

    // 채팅방을 나간 유저정보를 받아 화면에 처리한다.
    socket.on('recLeaveUser', (data) => {
      // dispatch(getThreads(user.farmname));
     
      // 접속된 클라이언트가 채팅방에서 나온 유저인 경우 
      if(data.participantUuid === user.uuid){
        // history.push('/app/chat/new');
        // dispatch(updateActiveThreadId(''));
        // setActiveThreadState({
        //   ...activeThreadState,
        //   activeThreadId : '',
        // });
        let chatUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/chat/new`;
        // console.log('recChatUrl',chatUrl);
        // history.push(`/app/chat/${newMessage.threadKey}`);
        window.location.href = chatUrl;

        // setSnackBarState({
        //   ...snackBarstate,
        //   open: true,
        //   vertical: 'top',
        //   horizontal: 'center',
        //   message: `채팅방에서 나왔습니다.`
        // })

      } else {
        setSnackBarState({
          ...snackBarstate,
          vertical: 'top',
          horizontal: 'center',
          open: true,
          message: `${data.message}`
        })
      }
      setTimeout(() =>
        setSnackBarState({
          ...snackBarstate,
          open: false,
          vertical: 'top',
          horizontal: 'center',
          message: ``
        })
      ,10000) // 10초 후에 snackBar state를 초기화 한다.
      // console.log('recLeaveUser',data.message);
    })

    socket.on('recCloseThread', (data) => {
      dispatch(getThreads(user.farmname));
      // console.log('recCloseThread', data)
      if( data.host !== user.name) {
        history.push('/app/chat/new');

        // setActiveThreadState({
        //   ...activeThreadState,
        //   activeThreadId : '',
        // });
      }
      setSnackBarState({
        ...snackBarstate,
        open: true,
        vertical: 'top',
        horizontal: 'center',
        message: `${data.threadname} 채팅방이 비활성화 되었습니다.`
      })

    })

    socket.on('recOpenThread', (data) => {
      dispatch(getThreads(user.farmname));
      
      // history.push('/app/chat/new');

      // setActiveThreadState({
      //   ...activeThreadState,
      //   activeThreadId : '',
      // });
      
      setSnackBarState({
        ...snackBarstate,
        open: true,
        vertical: 'top',
        horizontal: 'center',
        message: `${data.threadname} 채팅방이 활성화 되었습니다.`
      })
    });
  },[])

  useEffect(() => {
    // console.log('top rendering!')
    // console.log('chat.activeThreadId', chat.activeThreadId, 'currentThreadKey', currentThreadKey)
   
    setMenuState({
      selectedMenu: menu.selectedMenu,
      path: menu.path,
      lastMenu: menu.lastMenu,
      lastMenuPath: menu.lastMenuPath,
    });
    // console.log('selectedMenu', menu.selectedMenu);
    socket.on('getThreads', (data) => {
      dispatch(getParticipants(user.farmname, data.threadKey));
      setTimeout(() => 
        dispatch(getThreads(user.farmname))
      ,1000);
           
      const receipients = data.receipientUuidArray;
      const sendername = data.sendername;
      console.log('currentThreadKey', currentThreadKey);
      if(receipients.indexOf(user.uuid) !== -1){ // 새로 생성된 채팅방의 참가자인 경우
        // if( currentThreadKey === 'new' ||  currentThreadKey === 'device'){ // 활성화된 채팅방이 없는 경우 (로비에 있을 경우만)
          // let chatUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/chat/${data.threadKey}`;
          // window.location.href = chatUrl; // 새로생성된 채팅방을 로드한다.         
          // history.push(`/app/chat/${data.threadKey}`);
        //--------------------------------------------------------
        // 메인화면에서 채팅방에 초대 되었을 때 처리 로직 연구해보기
        // } 
        // else if( currentThreadKey === 'device' && chat.activeThreadId === null) { // 대시보드 화면에 있을 경우
        //   console.log('Dashboard');
        //   // let chatUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/chat/${data.threadKey}`;
        //   // window.location.href = chatUrl; // 새로생성된 채팅방을 로드한다. 
        //   // dispatch(getParticipants(user.farmname, data.threadKey));
        //   // setTimeout(() => 
        //   //   dispatch(updateActiveThreadId(data.threadKey))
        //   // ,1000);
        // }
        setSnackBarState({
          ...snackBarstate,
          open: true,
          vertical: 'bottom',
          horizontal: 'left',
          message: `${sendername} 님이 채팅에 초대했습니다.`,
          threadKey: data.threadKey,
        });
      } else {
        setSnackBarState({
          ...snackBarstate,
          open: true,
          vertical: 'bottom',
          horizontal: 'left',
          message: `${sendername} 님이 ${data.message}`,
          threadKey: '',
        });
      }

      setTimeout(() =>
        setSnackBarState({
          ...snackBarstate,
          open: false,
          message: ``,
          threadKey: data.threadKey,
        })
      ,10000) // 10초 후에 snackBar state를 초기화 한다.
      // console.log('getThreads',data.message);
     
    })
  },[isMountedRef, menu.selectedMenu, chat.activeThreadId, chat.thread])
  
  return (
    // <PerfectScrollbar options={{ suppressScrollX: true }} className={classes.scrollBox}>
    <Box className={classes.root} name={'DashboardLayout Root'}>
      <Grid container >
        <Grid item className={classes.nav}>
          <div >
            <NavBar
              onMobileClose={() => setState({
                ...state,
                isMobileNavOpen: false,
              })}
              openMobile={state.isMobileNavOpen}
              changeMenu={(title) => setState({
                ...state,
                selectedMenu: title
              })} 
            /> 
          </div>
        </Grid>
        <Grid item md={12} className={classes.wrapperGrid}>
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>

              <div name='dashboardLayout' className={classes.content}>
                <TopBar 
                  className={classes.topbar}
                  onMobileNavOpen={() => setState({
                    ...state,
                    isMobileNavOpen: true,
                  })}
                  onToggleDrawer={() => setState({
                    ...state,
                    isMobileNavOpen: !state.isMobileNavOpen,
                  })}
                  selectedmenu={state.selectedMenu}
                />
                {children}
              </div>
            </div>
          </div>
        </Grid>
        <Snackbar
          // 채팅방이 새로 생성되거나 유저가 차단된 경우 snackBar 요소로 알려준다. 
          anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
          open={open}
          onClose={handleClose}
          autoHideDuration={10000}
          message={message}
          key={vertical + horizontal}
          action={
            snackBarstate.threadKey !== '' ?
            <>
              {/* <React.Fragment>
                <IconButton size="small" aria-label="enter" color="inherit" onClick={handleClickEnter}>
                  <BiLogIn color="#4BB9D6"/>
                </IconButton>
                <Button className={classes.actionButton} onClick={handleClickEnter}>들어가기</Button>
              </React.Fragment> */}
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            </>
            :
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Grid>
    </Box>
    // </PerfectScrollbar>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;

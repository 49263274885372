import React, {useState} from 'react';
import 'date-fns';
import 'src/App.css';
import axios from 'src/utils/axios';
import { 
Box,
Button,
Card,
Modal,
Typography,
makeStyles,
TextField
} from "@material-ui/core";

import { useSelector, useDispatch } from 'src/store';
import { updateFarm } from 'src/slices/farm';

// 모달 내부 좌표 위치
function rand() {
return Math.round(Math.random() * 20) - 10;
// return Math.round(Math.random() * 10) - 15;
}

// 농장 추가 모달
function getFarmAddModalStyle() {
  const top = 50 + rand();
  // const left = 50 + rand();

  return {
    // top: `${top}%`,
    top: `calc( 50% - 325px )`,
    margin: "auto"
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: "calc( 100% - 64px )",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
  },
  button: {
    width: "234px",
    height: "48px",
    backgroundColor: "#00A8F0",
    fontFamily: "Noto Sans CJK KR",
    fontStyle:"normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "#FFFFFF",
    lineHeight: "22.4px",
    "&:hover":{
      backgroundColor: "#1a7fc7",
    }
  },
  buttonText: {
      fontFamily: 'Noto Sans CJK KR',
      color: theme.palette.primary.main,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.2px',
      [theme.breakpoints.down("md")] : {
      fontSize: '12px',
      }
  },
  buttonType : {
      height : "34px",
      display: "flex",
      alignItems: "center",
      fontFamily: 'Noto Sans CJK KR',
      color: theme.palette.common.white,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.2px',
      [theme.breakpoints.down("md")] : {
      fontSize: '12px',
      }
  },
  farmAddCard: {
    backgroundColor: theme.palette.background.dark,
  },
  farmAddCardEmpty: {
    backgroundColor: theme.palette.background.dark,
    height: "140.8px",
    color: "rgba(24, 24, 25, 0.6)",
    paddingTop: "60px"
  },
  farmAddBox: {
    backgroundColor: "#FFFFFF",
    height: "32px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Noto Sans CJK KR",
    fontStyle:"normal",
    fontWeight: 400,
    fontSize: "13px",
    color: theme.palette.primary.main,
    lineHeight: "18.2px",
  },
  farmAddBoxModal: {
    // backgroundColor: "#FFFFFF",
    height: "32px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Noto Sans CJK KR",
    fontStyle:"normal",
    fontWeight: 400,
    fontSize: "13px",
    color: theme.palette.primary.main,
    // lineHeight: "18.2px",
  },
  formControl :{
    width: "100%",
  },
  farmAddName: {
    // backgroundColor: "#FFFFFF",
    // height: "32px",
    // borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Noto Sans CJK KR",
    fontStyle:"normal",
    fontWeight: 400,
    fontSize: "12px",
    color: "rgba(24, 24, 25, 0.6)",
    lineHeight: "16.8px",
  },
  farmAddType: {
    // backgroundColor: "#FFFFFF",
    // height: "32px",
    // borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Noto Sans CJK KR",
    fontStyle:"normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#181819",
    lineHeight: "19.6px",
  },
  paper: {
      position: 'absolute',
      width: 771,
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: 8,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("md")]:{
      width: "100%"
      }
  },
}));

const FarmAddButton = () => {
    const classes = useStyles();
    const farm = useSelector((state) => state.farm.value); // store 구독
    const dispatch = useDispatch();
    const [farmAddModalStyle] = useState(getFarmAddModalStyle);
    const [openFarmAdd, setOpenFarmAdd] = useState(false);
    // 새로운 농장 정보
    const [newFarm, setNewFarm] = useState({
      userId: "",
      farmName: "",
      nation: "",
      address: "",
      timezone: "",
      wtankCount: 0,
      totalIndividuals: 0,
      activatedNumber: 0,
      totalFeed: 0
    });

  var farms = [];

  // 농장 추가 모달
  const handleOpenFarmAdd = () => {
    setOpenFarmAdd(true);
  };

  const handleCloseFarmAdd = () => {
    setOpenFarmAdd(false);
  };

  // 농장 추가
  const addFarm = async ( userId, farmName, nation, address, timezone, wtankCount, totalIndividuals, activatedNumber, totalFeed ) => {
    if(newFarm) {
      
      const response = await axios.post('/api/farm/add', {
        userId,
        farmName,
        nation,
        address,
        timezone,
        wtankCount,
        totalIndividuals,
        activatedNumber,
        totalFeed,
      });

      // farm 정보를 가져온다.
      const responseFarm = await axios.get('/api/farm/list');

      // console.log(response.data);
      // console.log(responseFarm.data);
      const newFarmData = response.data;
      if(newFarmData.newFarm) {
        // console.log(newFarmData.newFarm.farmname);
        alert(`${newFarmData.newFarm.farmname} 농장이 추가 되었습니다.`);
        handleCloseFarmAdd();

        // farm select 배열을 초기화 한다.
        farms = []

        let newFarm = responseFarm.data['items'];
        Object.keys(newFarm).forEach((key) => {
          // console.log(newFarm[key]);
          let farm = newFarm[key];
          let labelArray = farm.farmname.split('_');
          let label = labelArray[0].toUpperCase()+" "+labelArray[1].toUpperCase();
          farms.push({
            value: farm.farmname,
            label: label,
          })
        })

        // farm(농장 정보) state를 설정 한다. (추가된 농장을 select 목록에 반영되도록 한다.)
        let currentFarmState = {
          ...farm,
          farmData : responseFarm.data['items'],
          farmSelectList: farms,
        }

        // farm(농장 정보) store state를 업데이트 한다. 
        dispatch(updateFarm(currentFarmState));

        // console.log(farm);
      }else {
        alert(newFarmData.message);
      }
    }else {
      console.log("no valid farm.")
    }
  };

  // 새로운 사용자의 정보 state를 업데이트 한다.
  const handleNewFarmInfoChange = (event) => {
    const key =  event.target.name;
    setNewFarm({
      ...newFarm,
      [key] : event.target.value,
    })
  }

  // 농장 추가 모달 컨텐츠
  const bodyFarmAdd = (
    <Box style={farmAddModalStyle} className={classes.paper}>
      <Box p={2} id="simple-modal-title">농장 추가</Box>
      <Box id="simple-modal-description">
        <Card className={classes.farmAddCard}>
          <Box p={2} mx={1}>
            
              <Box mb={1} display="flex">
                사용자 아이디(email)<Typography style={{color:'red'}}>*</Typography>
              </Box>
              <Box className={classes.farmAddType}>
                <TextField
                  fullWidth
                  // required
                  id="userId"
                  name="userId"
                  // label="Required"
                  type="email"
                  defaultValue={newFarm.userId}
                  onChange={handleNewFarmInfoChange}
                  variant="outlined"
                  size="small"
                />
              </Box>
          </Box>
          <Box p={2} mx={1}>
            
              <Box mb={1} display="flex" >
                농장이름<Typography style={{color:'red'}}>*</Typography>
              </Box>
              <Box className={classes.farmAddType}>
                <TextField
                  fullWidth
                  // required
                  id="farmName"
                  name="farmName"
                  // label="Required"
                  defaultValue={newFarm.farmName}
                  onChange={handleNewFarmInfoChange}
                  variant="outlined"
                  size="small"
                />
              </Box>
          </Box>
          <Box p={2} mx={1}>
            <Box mb={1}>
              국가
            </Box>
            <Box className={classes.farmAddType}>
              <TextField
                fullWidth
                // required
                id="nation"
                name="nation"
                // label="Required"
                // type="password"
                defaultValue={newFarm.nation}
                onChange={handleNewFarmInfoChange}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>
          <Box p={2} mx={1}>
            <Box mb={1}>
              주소
            </Box>
            <Box className={classes.farmAddType}>
              <TextField
                fullWidth
                // required
                id="address"
                name="address"
                // label="Required"
                defaultValue={newFarm.address}
                onChange={handleNewFarmInfoChange}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>
          <Box p={2} mx={1}>
            <Box mb={1}>
              시간대
            </Box>
            <Box className={classes.farmAddType}>
              <TextField
                fullWidth
                // required
                id="timezone"
                name="timezone"
                // label="Required"
                defaultValue={newFarm.timezone}
                onChange={handleNewFarmInfoChange}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>
          {/* 자동 업데이트 항목 */}
          {/* <Box p={2} mx={1}>
            <Box mb={1}>
              총수조수
            </Box>
            <Box className={classes.farmAddType}>
              <TextField
                fullWidth
                // required
                id="wtankCount"
                name="wtankCount"
                // label="Required"
                defaultValue={newFarm.wtankCount}
                onChange={handleNewFarmInfoChange}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>
          <Box p={2} mx={1}>
            <Box mb={1}>
              총개체수
            </Box>
            <Box className={classes.farmAddType}>
              <TextField
                fullWidth
                // required
                id="totalIndividuals"
                name="totalIndividuals"
                // label="Required"
                defaultValue={newFarm.totalIndividuals}
                onChange={handleNewFarmInfoChange}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>
          <Box p={2} mx={1}>
            <Box mb={1}>
              활성수조수
            </Box>
            <Box className={classes.farmAddType}>
              <TextField
                fullWidth
                // required
                id="activatedNumber"
                name="activatedNumber"
                // label="Required"
                defaultValue={newFarm.activatedNumber}
                onChange={handleNewFarmInfoChange}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>
          <Box p={2} mx={1}>
            <Box mb={1}>
              총급이량
            </Box>
            <Box className={classes.farmAddType}>
              <TextField
                fullWidth
                // required
                id="totalFeed"
                name="totalFeed"
                // label="Required"
                defaultValue={newFarm.totalFeed}
                onChange={handleNewFarmInfoChange}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box> */}
          {/* -------------------------------------------- */}
          {/* <Box p={2} mx={1}>
            
              <Box mb={1}>
                권한
              </Box>
              <Box className={classes.farmAddType}>
                <TextField
                  fullWidth
                  id="authority"
                  name="authority"
                  margin="normal"
                  select
                  label="권한 선택"
                  // value={values.tank}
                  onChange={handleNewFarmInfoChange}
                  variant="outlined"
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  className={classes.parameterSelect}
                  // helperText="Please select your role"
                >
                  <option aria-label="None" value="" />
                  <option value={"owner"}>Owner</option>
                  <option value={"manager"}>Manager</option>
                  <option value={"viewer"}>Viewer</option>
                </TextField>
              </Box>
          </Box> */}
                    
        </Card>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          pt={2}
        > 
          <Box mr={1}>
            <Button
              // color="secondary"
              variant="contained"
              size="medium"
              onClick={handleCloseFarmAdd}
              // className={classes.buttonType}
            >
              <Typography className={classes.buttonType}>
                취소
              </Typography>
            </Button>
          </Box>
          <Box>
            <Button
              color="secondary"
              variant="contained"
              size="medium"
              onClick={() => addFarm(newFarm.userId, newFarm.farmName, newFarm.nation, newFarm.address, newFarm.timezone, newFarm.wtankCount, newFarm.totalIndividuals, newFarm.activatedNumber, newFarm.totalFeed)}
              // component={RouterLink}
              // to={`/api/userAdd/add`}
              // className={classes.buttonType}
            >
              <Typography className={classes.buttonType}>
                추가
              </Typography>
            </Button>
          </Box>

        </Box>
      </Box>
    </Box>
  );
  return(
    <Box>
        {/* 사육 정보 수정 버튼 */}
        <Button 
            disableElevation 
            className={classes.button}
            onClick={handleOpenFarmAdd}
          >
            + 새로운 농장 추가
        </Button>
      {/* 농장 추가 모달 */}
      <Modal
        open={openFarmAdd}
        onClose={handleCloseFarmAdd}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
      >
        {bodyFarmAdd}
      </Modal>
    </Box>
  
  )

}

export default FarmAddButton;


import React, { useState, useRef } from 'react';
import { 
  NavLink as RouterLink,
  useHistory
} from 'react-router-dom';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapse,
  ListItem,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import useMenu from 'src/hooks/useMenu';
import { useSelector, useDispatch } from 'src/store';
import { updateMenu } from 'src/slices/menu';
import useAuth from 'src/hooks/useAuth';


const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 4,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    borderRadius: 13,
    // justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    // width: '100%',
    minWidth: 40,
    fontWeight: theme.typography.fontWeightLight,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightLight,
        color: 'rgba(255, 255, 255, 0.7)'
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    
    // marginRight: theme.spacing(1)
  },
  title: {
    fontFamily: "Roboto",
    fontStyle:"normal",
    // fontWeight: 400,
    fontSize: "14px",
    color: "#FFFFFF",
    lineHeight: "19.6px",
    marginRight: 'auto'
  },
  active: {
    backgroundColor: theme.palette.background.menuButton, // #28439D
    '&.depth-0': {
      '& $title': {
        color: "#FFFFFF",
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    '& $title': {
      fontWeight: 500
    },
    '& $icon': {
      color: "#00A8F0"
    }
  },
  popover: {
    width: 200
  }
}));


const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  currentFarm,
  ...rest
}) => {
  const classes = useStyles();
  const { user, logout } = useAuth();
  const farm = useSelector((state) => state.farm.value);
  const menu = useSelector((state) => state.menu.value);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(openProp);
  const [values, setValues] = useState({
    selectedMenu: menu.selectedMenu,
  });
  const history = useHistory();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  // console.log('NavItem :' + farm.selectedFarm);

  const handleOpen = (event) => {
    setIsOpen(true);
    // let newMenu = { 
    //   selectedMenu: event.target.innerHTML
    // };
    // dispatch(updateMenu(newMenu));
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDashboardClick = () => {
    let homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/reports/dashboard/device`;
    window.location.href = homeUrl; // 대시보드 화면을 로드한다.
  }

  const handleLogout = () => {
    try {
      handleClose();
      logout(user.uuid); // userid 계정의 active, lastactivity를 업데이트 한다.
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSave = (event) => {
    let newMenu = { selectedMenu: event.target.innerHTML};
    dispatch(updateMenu(newMenu));
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      value={title}
      // onClick={(event) => console.log(event.target.value)}
      {...rest}
    >
      { title === '' && (
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          style={style}
          to={href}
          title={title}
          onClick={(event) => handleOpen(event)} // 메뉴를 클릭할때마다 state 값을 업데이트 한다.
          ref={ref}
          // onClick={(event) => console.log(event.target.value)}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="24"
            />
          )}
          {/* <span className={classes.title}>
            {title}
          </span> */}
          {Info && <Info />}
        </Button>
      )}

      { title === 'dashboard' && (
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          style={style}
          to={href}
          title={title}
          // onClick={() => handleDashboard Click()} // 메뉴를 클릭할때마다 state 값을 업데이트 한다.
          ref={ref}
          // onClick={(event) => console.log(event.target.value)}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="24"
            />
          )}
          {/* <span className={classes.title}>
            {title}
          </span> */}
          {Info && <Info />}
        </Button>
      )}
      
      { title === 'setting' && (
        <>
          <Button
            // activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            // component={RouterLink}
            // exact
            style={style}
            // to={href}
            title={title}
            onClick={(event) => handleOpen(event)} // 메뉴를 클릭할때마다 state 값을 업데이트 한다.
            ref={ref}
            // onClick={(event) => console.log(event.target.value)}
          >
            {Icon && (
              <Icon
                className={classes.icon}
                size="24"
              />
            )}
            {/* <span className={classes.title}>
              {title}
            </span> */}
            {Info && <Info />}
          </Button>
          <Menu
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
            >
            {/* <MenuItem
              component={RouterLink}
              to="/app/account"
            >
              계정정보
            </MenuItem> */}
            <MenuItem 
              // onClick={handleLogout}
            >
              준비중입니다.
            </MenuItem>
          </Menu>
        </>
      )}
       
  
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  changeMenu: PropTypes.func
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;

import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
// import { MenuContextProvider } from 'src/contexts/MenuContext'; //provider 불러오기

export const renderRoutes = (routes = []) => (
  // 형제 컴포넌트를 감싼다.
  // <MenuContextProvider>
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
  // </MenuContextProvider>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/password-checkemail',
    component: lazy(() => import('src/views/auth/PasswordResetView'))
  },
  {
    exact: true,
    path: '/password-reset',
    component: lazy(() => import('src/views/auth/PasswordResetView/PasswordReset'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: [
          '/app/chat/new',
          '/app/chat/:threadKey'
        ],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },

      // 공고 관리
      {
        exact: true,
        path: '/app/management/announces',
        component: lazy(() => import('src/views/announce/AnnounceListView'))
      },
      {
        exact: true,
        path: '/app/management/announces/import',
        component: lazy(() => import('src/views/announce/AnnounceImportView'))
      },
      {
        exact: true,
        path: '/app/management/announces/:announceId',
        component: lazy(() => import('src/views/announce/AnnounceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/announces/:announceId/edit',
        component: lazy(() => import('src/views/announce/AnnounceEditView'))
      },


      // 수조 리스트
      {
        exact: true,
        path: '/app/management/tanks',
        component: lazy(() => import('src/views/wtank/WtankListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/import',
        component: lazy(() => import('src/views/wtank/CustomerImportView'))
      },
      // 수조 정보 상세보기
      {
        exact: true,
        path: '/app/management/tankdetails',
        component: lazy(() => import('src/views/wtank/WtankDetailsView'))
      },
      // {
      //   exact: true,
      //   path: '/app/management/customers/:customerNo/edit',
      //   component: lazy(() => import('src/views/customer/CustomerEditView'))
      // },
      
      // 분석 차트
      {
        exact: true,
        path: '/app/management/analysis',
        component: lazy(() => import('src/views/wtank/AnalysisChart/ListView/index'))
      },
      {
        exact: true,
        path: '/app/management/suyonggas/import',
        component: lazy(() => import('src/views/suyongga/ImportView'))
      },
      {
        exact: true,
        path: '/app/management/suyonggas/:suyonggaNo',
        component: lazy(() => import('src/views/suyongga/DetailView'))
      },
      {
        exact: true,
        path: '/app/management/suyonggas/:suyonggaNo/edit',
        component: lazy(() => import('src/views/suyongga/EditView'))
      },

      // 수도 계량기정보
      {
        exact: true,
        path: '/app/management/notification',
        component: lazy(() => import('src/views/wtank/Notification'))
      },
      {
        exact: true,
        path: '/app/management/wmeters/import',
        component: lazy(() => import('src/views/wmeter/ImportView'))
      },
      {
        exact: true,
        path: '/app/management/wmeters/:no',
        component: lazy(() => import('src/views/wmeter/DetailView'))
      },
      {
        exact: true,
        path: '/app/management/wmeters/:no/edit',
        component: lazy(() => import('src/views/wmeter/EditView'))
      },



      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      // 세대별 과금정보
      {
        exact: true,
        path: '/app/management/payments',
        component: lazy(() => import('src/views/payment/PaymentListView'))
      },
      {
        exact: true,
        path: '/app/management/payments/:paymentId',
        component: lazy(() => import('src/views/payment/PaymentDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/project/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      // dashboard
      // dashboard-electricity 전기
      // dashboard-water       수도
      // dashboard-gas         가스
      // dashboard-calori      열량
      // dashboard-hotwater    온수
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-electricity',
        component: lazy(() => import('src/views/reports/DashboardElectricityView'))
      },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard-water',
      //   component: lazy(() => import('src/views/reports/DashboardWaterView'))
      // },
      // dashboard > Overview
      {
        exact: true,
        path: '/app/reports/dashboard/device',
        component: lazy(() => import('src/views/reports/DashboardDeviceView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard/device/pdf',
        component: lazy(() => import('src/views/reports/DashboardDeviceView/ReportPdfView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-gas',
        component: lazy(() => import('src/views/reports/DashboardGasView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-calori',
        component: lazy(() => import('src/views/reports/DashboardCaloriView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-hotwater',
        component: lazy(() => import('src/views/reports/DashboardHotWaterView'))
      },
      {
        exact: true,
        path: '/app/reports/daily',
        component: lazy(() => import('src/views/reports/ReportDailyView'))
      },
      // 농장 설정
      {
        exact: true,
        path: '/app/reports/dashboard/farmsetting',
        component: lazy(() => import('src/views/reports/FarmSetting'))
      },
      // 사용자 관리
      {
        exact: true,
        path: '/app/reports/dashboard/usermanage',
        component: lazy(() => import('src/views/reports/UserManageView'))
      },
      {
        exact: true,
        path: '/app/reports/period',
        component: lazy(() => import('src/views/reports/ReportPeriodView'))
      },

      {
        exact: true,
        path: '/app/reports/search',
        component: lazy(() => import('src/views/reports/ReportSuyonggaSearchView'))
      },

      {
        exact: true,
        path: '/app/reports/eventlist',
        component: lazy(() => import('src/views/reports/EventListView'))
      },


      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard/farm" />
      },

      {
        exact: true,
        path: '/app/opening/total',
        component: lazy(() => import('src/views/opening/Total'))
      },
      {
        exact: true,
        path: '/app/opening/today',
        component: lazy(() => import('src/views/opening/Today'))
      },
      {
        exact: true,
        path: '/app/opening/error',
        component: lazy(() => import('src/views/opening/ErrorList'))
      },
      {
        exact: true,
        path: '/app/board/customask',
        component: lazy(() => import('src/views/board/customAsk'))
      },
      {
        exact: true,
        path: '/app/board/chat',
        component: lazy(() => import('src/views/board/Chat'))
      },

      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard/device" /> // 기본 호출 url 설정
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        // component: HomeView,
        component: () => <Redirect to="/login" />
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  activeThreadId: null,
  contacts: {
    byId: {},
    allIds: []
  },
  threads: {
    byId: {},
    allIds: []
  },
  participants: [],
  recipients: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getContacts(state, action) {
      const { contacts } = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },
    getThreads(state, action) {
      const { threads } = action.payload;

      state.threads.byId = objFromArray(threads);
      state.threads.allIds = Object.keys(state.threads.byId);
    },
    getThread(state, action) {
      const { thread } = action.payload;

      if (thread) {
        state.threads.byId[thread.id] = thread;
        state.activeThreadId = thread.id;
  
        if (!state.threads.allIds.includes(thread.id)) {
          state.threads.allIds.push(thread.id);
        }
      } else {
        state.activeThreadId = null;
      }
    },
    updateThread(state, action) {
      const { thread } = action.payload;
      // console.log(thread);
      if (thread) {
        // state.threads.byId[thread.id] = thread;
        state.activeThreadId = thread.id;
        // console.log("updateThread",typeof state.threads.byId[thread.id]['messages']);
        let messages = state.threads.byId[thread.id]['messages'].push(thread);
        // state.threads.byId[thread.id]['messages'] = [...messages, thread];
        // console.log("messages",messages);

      } else {
        // state.activeThreadId = null;
      }
    },
    markThreadAsSeen(state, action) {
      const { threadId } = action.payload;
      const thread = state.threads.byId[threadId];

      if (thread) {
        thread.unreadCount = 0;
      }
    },
    resetActiveThread(state) {
      state.activeThreadId = null;
    },
    getParticipants(state, action) {
      const { participants } = action.payload;

      state.participants = participants;
    },
    addRecipient(state, action) {
      const { recipient } = action.payload;
      const exists = state.recipients.find((_recipient) => _recipient.uuid === recipient.uuid);

      if (!exists) {
        state.recipients.push(recipient);
      }
    },
    resetRecipients(state) {
      state.recipients = [];
    },
    removeRecipient(state, action) {
      const { recipientUuid } = action.payload;

      state.recipients = state.recipients.filter((recipient) => recipient.uuid !== recipientUuid);
    },
    blockParticipant(state, action) {
      const { participantUuid } = action.payload;
      // console.log('blockParticipant',participantUuid);
      state.participants = state.participants.filter((participant) => participant.uuid !== participantUuid);
      // console.log('state.participants',state.participants);
    },
    leaveThread(state, action) {
      const { participantUuid } = action.payload;
      // console.log('leaveThread',participantUuid);
      state.participants = state.participants.filter((participant) => participant.uuid !== participantUuid);
      // console.log('state.participants',state.participants);
    },
    updateActiveThreadId(state, action) {
      const { threadKey } = action.payload;
      // console.log('updateActiveThreadId',threadKey);
      state.activeThreadId = threadKey;
      // console.log('state.activeThreadId',state.activeThreadId);
    },
  }
});

export const reducer = slice.reducer;

export const getContacts = (farmname) => async (dispatch) => {
  const response = await axios.get('/api/chat/contacts', {
    params: {farmname: farmname,}
  });
  // console.log("getContacts",response.data);
  dispatch(slice.actions.getContacts(response.data));
};

export const getThreads = (farmname) => async (dispatch) => {
  const response = await axios.get('/api/chat/threads', {
    params : { farmname: farmname }
  });
  // console.log("getThreads",response.data);
  dispatch(slice.actions.getThreads(response.data));
};

export const getThread = (threadKey, farmname) => async (dispatch) => {
  const response = await axios.get('/api/chat/thread', {
    params: {
      threadKey,
      farmname
    }
  });

  // console.log("getThread",response.data);
  dispatch(slice.actions.getThread(response.data));
};

// 채팅 스레드에 직접 요소를 추가해준다.
export const updateThread = (newThread) => async (dispatch) => {

  // console.log("updateThread",newThread);

  dispatch(slice.actions.updateThread(newThread));
};

export const markThreadAsSeen = (threadId) => async (dispatch) => {
  await axios.get('/api/chat/thread/mark-as-seen', {
    params: {
      threadId
    }
  });

  dispatch(slice.actions.markThreadAsSeen({ threadId }));
};

export const resetActiveThread = () => (dispatch) => {
  dispatch(slice.actions.resetActiveThread());
};

export const getParticipants = (threadKey,farmname) => async (dispatch) => {
  const response = await axios.get('/api/chat/participants', {
    params: {
      threadKey,
      farmname
    }
  });

  // console.log("getParticipants",response.data);
  dispatch(slice.actions.getParticipants(response.data));
};

export const addRecipient = (recipient) => (dispatch) => {
  dispatch(slice.actions.addRecipient({ recipient }));
};

export const resetRecipients = () => (dispatch) => {
  dispatch(slice.actions.resetRecipients());
};

export const removeRecipient = (recipientUuid) => (dispatch) => {
  dispatch(slice.actions.removeRecipient({ recipientUuid }));
};

export const blockParticipant = (threadKey, accparticipantsString, type, participantsString, participantUuid ) => async (dispatch) => {
  const response = await axios.post('/api/chat/list/updateparticipant',{threadid: threadKey, accparticipantsString: accparticipantsString, type: type, participantsString: participantsString, participantUuid: participantUuid});
  // console.log('blockParticipant', response.data);
  
  const responseThread = await axios.get('/api/chat/thread', {
    params: {
      threadKey
    }
  });
  dispatch(slice.actions.getThread(response.data));
  dispatch(slice.actions.blockParticipant({ participantUuid }));
};

export const leaveThread = (threadKey, accparticipantsString, type, participantsString, participantUuid ) => async (dispatch) => {
  const response = await axios.post('/api/chat/list/updateparticipant',{threadid: threadKey, accparticipantsString: accparticipantsString, type: type, participantsString: participantsString, participantUuid: ''});
  // console.log('leaveThread', response.data);
  
  const responseThread = await axios.get('/api/chat/thread', {
    params: {
      threadKey
    }
  });
  dispatch(slice.actions.getThread(response.data));
  dispatch(slice.actions.leaveThread({ participantUuid }));
};

export const updateActiveThreadId = (threadKey) => async (dispatch) => {
  
  // console.log('updateActiveThreadId', threadKey);

  dispatch(slice.actions.updateActiveThreadId({ threadKey }));
};

export default slice;

import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  value: {
    selectedWtank: "default",
    selectedWtankInfo:{
      number:0, // 선택된 수조번호로 초기화 한다.
      area: 0,
      length: 0,
      width: 0,
      depth: 0,
      shape:"",
      breedinfoId: null,
      position: "",
      inputDate: "",
      individuals: 0,
      totalPeriod: 0,
      currentPeriod: 0,
    },
    selectedWtankItem: undefined,
    estimatedInfo: {},
    estimatedInfoData: {},
    wtankData: {},
  }
};

const slice = createSlice({
  name: 'wtank',
  initialState,
  reducers: {
    updateWtank(state, action) {
      state.value  = action.payload;
      // console.log(typeof action.payload);
      // console.log("action.payload :"+action.payload);
      // console.log(state.value.selectedWtank);
    },
  }
});

export const reducer = slice.reducer;

// export const getEvents = () => async (dispatch) => {
//   const response = await axios.get('/api/calendar/events');

//   dispatch(slice.actions.getEvents(response.data));
// };

// export const createEvent = (data) => async (dispatch) => {
//   const response = await axios.post('/api/calendar/events/new', data);

//   dispatch(slice.actions.createEvent(response.data));
// };

// export const selectEvent = (eventId) => async (dispatch) => {
//   dispatch(slice.actions.selectEvent({ eventId }));
// };

export const updateWtank = (tankInfo) => async (dispatch) => {
  
  dispatch(slice.actions.updateWtank(tankInfo));
};

// export const deleteEvent = (eventId) => async (dispatch) => {
//   await axios.post('/api/calendar/events/remove', {
//     eventId
//   });

//   dispatch(slice.actions.deleteEvent({ eventId }));
// };

// export const selectRange = (start, end) => (dispatch) => {
//   dispatch(slice.actions.selectRange({
//     start: start.getTime(),
//     end: end.getTime()
//   }));
// };

// export const openModal = () => (dispatch) => {
//   dispatch(slice.actions.openModal());
// };

// export const closeModal = () => (dispatch) => {
//   dispatch(slice.actions.closeModal());
// };

// export const selectedFarm = (state) => state.farm.selectedFarm; 

export default slice;

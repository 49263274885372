import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Link,
  Select,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/constants';
import Image from 'src/components/Image';
import Flag from 'src/components/Flag'
import Logo from 'src/components/Logo';
import { BorderBottom } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,

  },
  topTextA: {
    fontFamily: 'Montserrat',
    fontSize: 28,
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 34,
    color: theme.palette.primary.main,
    letterSpacing: 0,
    textAlign: 'left'
  },
  topTextB: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    color: theme.palette.primary.main,
    // lineHeight: 17,
    letterSpacing: 0,
    textAlign: 'bottom',
    marginLeft: 10,
    paddingTop: 8
  },
  seperator: {
    margin: '0 10 0 10'
  },
  button: {
    border: '1px solid #3855B3',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: theme.palette.primary.main
  },
  toolbar: {
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  // divider: {
  //   width: 1,
  //   height: 32,
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2)
  // }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      // color="light"
      {...rest}
    >
      {/* <Hidden mdDown> */}
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
          <Image folder="topbar" filename="Code_BI_horizontal" width="262px" height="64px"/>
          </Box>
        </RouterLink>
        {/* <Hidden mdDown>
          <Box mx={5} />
        </Hidden> */}
          

        {/* <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            Version
            {' '}
            {APP_VERSION}
          </Typography>
        </Hidden> */}
        {/* <Box flexGrow={1} />
        <Grid>
          <Link
            className={classes.link}
            component={RouterLink}
            to="/app"
            underline="none"
            variant="body2"
          >
            <Button className={classes.button} >
              SIGN UP
            </Button>
          </Link>
        </Grid> */}
        {/* <Box mx={1} />
        <Typography className={classes.topTextA}>
          |
        </ Typography>
        <Box mx={1} /> */}

        {/* 언어 선택 셀렉트 - 추후 활성화 */}
        {/* <Select
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          disableUnderline
          defaultValue="ENG"
        >
          <MenuItem value='ENG'>
              <RouterLink to="/eng" >
                <Flag className={classes.logo} lang="eng" />
              </RouterLink>
          </MenuItem>
          <MenuItem value='KOR'>
              <RouterLink to="/kor" >
                <Flag className={classes.logo} lang="kor" />
              </RouterLink>
          </MenuItem>
        </Select> */}

        {/* <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app"
          underline="none"
          variant="body2"
        >
          Dashboard
        </Link> */}
        <Hidden mdDown>
         <Box mx={5} />
        </Hidden>
      </Toolbar>
      {/* </Hidden> */}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Hidden,
  Link,
  Button,
  SvgIcon,
  Toolbar,
  makeStyles
} from "@material-ui/core";
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 11px',
    
  },
  topTextA: {
    fontFamily: 'Montserrat',
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '33.6px',
    color: '#FFFFFF',
    letterSpacing: 0,
    textAlign: 'center'
  },
  topTextB: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFFFFF',
    lineHeight: '21.6px',
    letterSpacing: 0,
    textAlign: 'center',
    // marginLeft: 10,
    // paddingTop: 8
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  const {settings} = useSettings();
  
  return (
    // <Box className={classes.logoContainer}>
      <SvgIcon {...props}>
        {/* <img src={'/static/icons/icon_setting.svg'} width='24' title='Setting'/> */}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.52902 6.58788L11.4047 3.5207C11.6837 3.37505 12.0151 3.36926 12.299 3.50509L18.2782 6.36534C18.5415 6.49132 18.7367 6.72608 18.8124 7.008L20.6444 13.8279C20.7279 14.1387 20.6567 14.4707 20.4532 14.7199L15.8059 20.4106C15.6199 20.6385 15.3429 20.7728 15.0488 20.778L9.25939 20.879C8.96528 20.8842 8.68383 20.7595 8.48994 20.5384L7.73794 21.1975L8.48994 20.5383L3.64689 15.0133C3.43478 14.7713 3.35207 14.442 3.42466 14.1285L5.01755 7.2488C5.0834 6.9644 5.27023 6.72297 5.52902 6.58788Z" stroke="#858EA1" stroke-width="2" stroke-linejoin="round"/>
        <circle cx="11.9998" cy="11.9997" r="2.93198" stroke="#858EA1" stroke-width="2"/>
        </svg>
      </SvgIcon>
      
    // </Box>
  );
}

export default Logo;

import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as accountReducer } from 'src/slices/account'; // 추가
import { reducer as menuReducer } from 'src/slices/menu'; // 추가
import { reducer as farmReducer } from 'src/slices/farm'; // 추가
import { reducer as feedReducer } from 'src/slices/feed'; // 추가
import { reducer as feedactionReducer } from 'src/slices/feedaction'; // 추가
import { reducer as wtankReducer } from 'src/slices/wtank'; // 추가
import { reducer as deviceReducer } from 'src/slices/device'; // 추가
import { reducer as breedinfoReducer } from 'src/slices/breedinfo'; // 추가
import { reducer as parameterReducer } from 'src/slices/parameter'; // 추가
import { reducer as notificationReducer } from 'src/slices/notification'; // 추가
import { reducer as notificationlogReducer } from 'src/slices/notificationlog'; // 추가


const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  account: accountReducer, // 추가
  menu: menuReducer, // 추가
  farm: farmReducer, // 추가
  feed: feedReducer, // 추가
  feedaction: feedactionReducer, // 추가
  wtank: wtankReducer, // 추가
  device: deviceReducer, // 추가
  breedinfo : breedinfoReducer, // 추가
  parameter: parameterReducer, // 추가
  notification: notificationReducer, // 추가
  notificationlog: notificationlogReducer //추가
});

export default rootReducer;

import React from 'react';

const Image = (props) => {
  let src = "/static/"+props.folder+"/"+props.filename+".svg"
  return (
    <img
      alt={props.filename}
      src={src}
      style={{width : props.width, height : props.height, color : props.color}}
      {...props}
    />
  );
}

export default Image;

import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Hidden,
  Link,
  Button,
  Toolbar,
  makeStyles
} from "@material-ui/core";
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 11px',
    
  },
  topTextA: {
    fontFamily: 'Montserrat',
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '33.6px',
    color: '#FFFFFF',
    letterSpacing: 0,
    textAlign: 'center'
  },
  topTextB: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFFFFF',
    lineHeight: '21.6px',
    letterSpacing: 0,
    textAlign: 'center',
    // marginLeft: 10,
    // paddingTop: 8
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  const {settings} = useSettings();
  
  return (
    <Box className={classes.logoContainer}>
      <img src={settings.theme === 'LIGHT' ? '/static/code_logo_light.svg' : '/static/code_logo_dark.svg'} width='35' title='CodeMeter Monitor'/>
    </Box>
  );
}

export default Logo;

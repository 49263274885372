import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Hidden,
  Link,
  Button,
  SvgIcon,
  Toolbar,
  makeStyles
} from "@material-ui/core";
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 11px',
    
  },
  topTextA: {
    fontFamily: 'Montserrat',
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '33.6px',
    color: '#FFFFFF',
    letterSpacing: 0,
    textAlign: 'center'
  },
  topTextB: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFFFFF',
    lineHeight: '21.6px',
    letterSpacing: 0,
    textAlign: 'center',
    // marginLeft: 10,
    // paddingTop: 8
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  const {settings} = useSettings();
  
  return (
    
    // <Box className={classes.logoContainer}>
      <SvgIcon
      {...props}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_472_3278)">
        <path d="M6.30039 17.0003H5.88618L5.59328 17.2932L3.40039 19.4861V4.35027C3.40039 3.83005 3.83018 3.40027 4.35039 3.40027H19.9504C20.4706 3.40027 20.9004 3.83005 20.9004 4.35027V16.0503C20.9004 16.5705 20.4706 17.0003 19.9504 17.0003H6.30039Z" stroke="#858EA1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_472_3278">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        {/* <img src={'/static/icons/icon_chat.svg'} width='24' title='Chat'/> */}
      </SvgIcon>
    // </Box>
  );
}

export default Logo;

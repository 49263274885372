import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  value: {
    selectedMenu: '',
    path: '',
    lastMenu: '',
    lastMenuPath: ''
  }
};

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    updateMenu(state, action) {
      state.value =  action.payload;
      // console.log(state.value.selectedMenu);
    },
  }
});

export const reducer = slice.reducer;

// export const getEvents = () => async (dispatch) => {
//   const response = await axios.get('/api/calendar/events');

//   dispatch(slice.actions.getEvents(response.data));
// };

// export const createEvent = (data) => async (dispatch) => {
//   const response = await axios.post('/api/calendar/events/new', data);

//   dispatch(slice.actions.createEvent(response.data));
// };

// export const selectEvent = (eventId) => async (dispatch) => {
//   dispatch(slice.actions.selectEvent({ eventId }));
// };

export const updateMenu = (newMenu) => async (dispatch) => {
  
  dispatch(slice.actions.updateMenu(newMenu));
};

// export const deleteEvent = (eventId) => async (dispatch) => {
//   await axios.post('/api/calendar/events/remove', {
//     eventId
//   });

//   dispatch(slice.actions.deleteEvent({ eventId }));
// };

// export const selectRange = (start, end) => (dispatch) => {
//   dispatch(slice.actions.selectRange({
//     start: start.getTime(),
//     end: end.getTime()
//   }));
// };

// export const openModal = () => (dispatch) => {
//   dispatch(slice.actions.openModal());
// };

// export const closeModal = () => (dispatch) => {
//   dispatch(slice.actions.closeModal());
// };

export default slice;

import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  value: {
    selectedBreedinfo: "default",
    selectedBreedinfoInfo:{
      id: "",
      farmName: "",
      number: 0,
      nickname: "",
      name: "",  
      inputDate: "",
      position: "",
      individuals: 0,
      totalWeight: 0,
      seeds: "",    
      fromWhere: "",
      currentPeriod: 0,
      totalPeriod: 0,
      plOrder: 0,
      plNumber: 0,
      shipmentTarget: "",
      survivalRate: 0,
      fcr: 0,
    },
    breedinfoData: [],
  }
};

const slice = createSlice({
  name: 'breedinfo',
  initialState,
  reducers: {
    updateBreedinfo(state, action) {
      state.value  = action.payload;
      // console.log(typeof action.payload);
      // console.log("action.payload :"+action.payload);
      // console.log(state.value.selectedWtank);
    },
  }
});

export const reducer = slice.reducer;

// export const getEvents = () => async (dispatch) => {
//   const response = await axios.get('/api/calendar/events');

//   dispatch(slice.actions.getEvents(response.data));
// };

// export const createEvent = (data) => async (dispatch) => {
//   const response = await axios.post('/api/calendar/events/new', data);

//   dispatch(slice.actions.createEvent(response.data));
// };

// export const selectEvent = (eventId) => async (dispatch) => {
//   dispatch(slice.actions.selectEvent({ eventId }));
// };

export const updateBreedinfo = (breedinfo) => async (dispatch) => {
  
  dispatch(slice.actions.updateBreedinfo(breedinfo));
};

// export const deleteEvent = (eventId) => async (dispatch) => {
//   await axios.post('/api/calendar/events/remove', {
//     eventId
//   });

//   dispatch(slice.actions.deleteEvent({ eventId }));
// };

// export const selectRange = (start, end) => (dispatch) => {
//   dispatch(slice.actions.selectRange({
//     start: start.getTime(),
//     end: end.getTime()
//   }));
// };

// export const openModal = () => (dispatch) => {
//   dispatch(slice.actions.openModal());
// };

// export const closeModal = () => (dispatch) => {
//   dispatch(slice.actions.closeModal());
// };

// export const selectedFarm = (state) => state.farm.selectedFarm; 

export default slice;

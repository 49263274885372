import React, {useState, useCallback, useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import 'src/App.css';
import PropTypes from 'prop-types';
import {
  Avatar,
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  IconButton,
  InputAdornment,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Link,
  Select,
  SvgIcon,
  TextField,
  Tooltip,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { useSelector, useDispatch } from 'src/store';
import { updateMenu } from 'src/slices/menu';
import { updateAccount } from 'src/slices/account';
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  XCircle as XIcon
} from 'react-feather';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/utils/axios';
import { APP_VERSION } from 'src/constants';
import CodeLogo from 'src/components/CodeLogo';
import Image from 'src/components/Image';
import Flag from 'src/components/Flag'
import Logo from 'src/components/Logo';
import { BorderBottom } from '@material-ui/icons';
import { 
  FiSun,
  FiMoon
} from "react-icons/fi";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Settings from './Settings';
import useSettings from 'src/hooks/useSettings';
import Notifications from './Notifications';
import Messages from './Messages';
import AccountAvatar from 'src/components/AccountAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    // width: 'calc(100% - 256px)',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 68px)', // calc(100% - 256px)
    }
  },
  avatar: {
    width: 36,
    height:36,
  },
  noBorder: {  // input 요소 테두리 없애기
    border: 'none',

  },
  topLeft: {
    display:'flex',
    alignItems: 'center'
  },
  headTitle: {
    fontFamily: "Roboto",
    fontStyle:"normal",
    fontWeight: 600,
    fontSize: "16px",
    color: theme.palette.text.third,
    padding: '0px 20px 0px 0px',
    lineHeight: "19px",
    // marginTop: "42px"
  },
  topTextA: {
    fontFamily: 'Montserrat',
    fontSize: 28,
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 34,
    color: theme.palette.primary.main,
    letterSpacing: 0,
    textAlign: 'left'
  },
  topTextB: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    color: theme.palette.primary.main,
    // lineHeight: 17,
    letterSpacing: 0,
    textAlign: 'bottom',
    marginLeft: 10,
    paddingTop: 8
  },
  seperator: {
    margin: '0 10 0 10'
  },
  button: {
    border: '1px solid #3855B3',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: theme.palette.primary.main
  },
  toolbar: {
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: '0px 66px 0px 66px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px 0px 20px',
    }
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  queryField: {
    width: 300,
    backgroundColor: theme.palette.background.dark,
    borderRadius: 4,
    outline: 'none',
    [theme.breakpoints.down('md')] :{
      width: 200,
    },
    [theme.breakpoints.down('xs')] :{
      // width: 160,
      display: 'none',
    }
  },
  menuIcon: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  // divider: {
  //   width: 1,
  //   height: 32,
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2)
  // }
  backiosIcon: {
    color: theme.palette.text.backIcon,
    height: 12,
    width: 12,
  },
  Searchinput: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: 400,
    color: theme.palette.text.searchContent,
  },
  searchContent: {
    fontSize: 14.5,
    color: theme.palette.text.searchContent,
  },
  themeBox: {
    background: theme.palette.background.themeBox,
    borderRadius: '100px',
    marginRight: 8,
    [theme.breakpoints.down('xs')] : {
      background: 'none',
    }
  },
  themeIcon: {
    color: theme.palette.text.themeIcon,
  },
  
}));

const TopBar = ({ 
  className, 
  onMobileNavOpen,
  onToggleDrawer,
  ...rest 
}) => {
  const classes = useStyles();
  const { settings, saveSettings } = useSettings();
  const menu = useSelector((state) => state.menu.value); // store 구독
  const account = useSelector((state) => state.account.value) // store 구독
  const user = account.loginAccount;
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState(user ? user.avatar ? user.avatar : '/static/images/avatars/avatar_null.svg' : '/static/images/avatars/avatar_null.svg');
  const [query, setQuery] = useState("");
  // console.log('settings.theme', settings.theme);
  const [theme, setTheme] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const [menuState, setMenuState] = useState({
    selectedMenu: menu.selectedMenu,
    path: menu.path,
    lastMenu: menu.lastMenu,
    lastMenuPath: menu.lastMenuPath,
  })
  const isMountedRef = useIsMountedRef();


  const handleQueryChange = event => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleThemeClick = () => {
    // console.log(theme);
    if(theme.theme === 'LIGHT') {
      saveSettings({
        ...theme,  
        theme: 'ONE_DARK'
      });
      setTheme({
        ...theme,  
        theme: 'ONE_DARK'
      });
    }else {
      saveSettings({
        ...theme,  
        theme: 'LIGHT'
      });
      setTheme({
        ...theme,  
        theme: 'LIGHT'
      });
    }
  }

  const handleHomeClick = () => {
    // 상단 메뉴이름을 설정한다.
    let currentMenu = {
      ...menu,
      selectedMenu: "Dashboard",
      path: '/app/reports/dashboard/device',
      lastMenu: menu.selectedMenu,
      lastMenuPath: menu.path
    }
    dispatch(updateMenu(currentMenu));

    // let homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/reports/dashboard/device`;

    // // 채팅 메세지 리랜더링 방지를 위해 챗팅방을 로드할때 브라우저를 새로고침 해준다.
    // window.location.href = homeUrl;
    
  }

  // 브라우저의 크기를 확인하여 메세지 리스트 요소의 높이를 계산한다.
  const getWindowSize = () => {
    const {innerWidth , innerHeight} = window;
    let height = innerHeight - 201; // topbar(64) + paddingTop(42) + paddingBottom(42) + titleGridHeight(53)
    return {innerWidth, height};
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  // 윈도우 크기가 변경되면 다시 렌더링 해준다.
  useEffect(() => {

    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    // console.log('top rendering!')
    setMenuState({
      selectedMenu: menu.selectedMenu,
      path: menu.path,
      lastMenu: menu.lastMenu,
      lastMenuPath: menu.lastMenuPath,
    });
  },[isMountedRef, menu.selectedMenu])

  const getAccount = useCallback( async () => {
    
    const response = await axios.get('/api/account/me');
    const { user } = response.data;
    setAvatar(user.avatar ? user.avatar : '/static/images/avatars/avatar_null.svg');
    // console.log('accountAvatar')
  },[isMountedRef, account.loginAccount])

  useEffect(() => {
    getAccount();
  },[getAccount])



  return (
    <>
    { menu.selectedMenu  !== '채팅 로비' && (
    <AppBar
      className={clsx(classes.root, className)}
      // color="light"
      {...rest}
    >
      {/* <Hidden mdDown> */}
      <Toolbar className={classes.toolbar} id="topbar">
        
        <Box className={classes.topLeft}>
          <Hidden smUp>
            {menuState.selectedMenu === 'Dashboard' && (
              <Box
                // pt={'30px'}
                display="flex"
                justifyContent="center"
                flexDirection='column'
                style={{cursor:'pointer'}}
                onClick ={handleHomeClick}
              >
                <RouterLink 
                  to="/app"
                  style={{textDecoration: 'none'}}
                >
                  <CodeLogo />
                </RouterLink>
              </Box>
            )}
            {menuState.selectedMenu === '맴버 채팅'  && (
              <Box
                // pt={'30px'}
                display="flex"
                justifyContent="center"
                flexDirection='column'
                style={{cursor:'pointer'}}
                onClick ={handleHomeClick}
              >
                <RouterLink 
                  to="/app"
                  style={{textDecoration: 'none'}}
                >
                  <CodeLogo />
                </RouterLink>
              </Box>
            )}
            {menuState.selectedMenu === '공개 공지사항'  && (
              <Box
                // pt={'30px'}
                display="flex"
                justifyContent="center"
                flexDirection='column'
                style={{cursor:'pointer'}}
                onClick ={handleHomeClick}
              >
                <RouterLink 
                  to="/app"
                  style={{textDecoration: 'none'}}
                >
                  <CodeLogo />
                </RouterLink>
              </Box>
            )}
          </Hidden>
          {menuState.selectedMenu !== 'Dashboard' && menuState.selectedMenu !== '맴버 채팅' && menuState.selectedMenu !== '공개 공지사항' && menuState.selectedMenu !== '' && (
            <Tooltip title='Home'>
              <IconButton 
                aria-label='home'
                component={RouterLink}
                to='/app'
                onClick={() => handleHomeClick()}
              >
                <ArrowBackIosIcon
                  className={classes.backiosIcon} 
                  
                  // style={{cursor: "pointer"}}
                />
              </IconButton>
            </Tooltip>
          )}
          {menuState.selectedMenu === '맴버 채팅' && windowSize.innerWidth > 600 && (
            <Tooltip title='Home'>
              <IconButton 
                aria-label='home'
                component={RouterLink}
                to='/app'
                onClick={() => handleHomeClick()}
              >
                <ArrowBackIosIcon
                  className={classes.backiosIcon} 
                  
                  // style={{cursor: "pointer"}}
                />
              </IconButton>
            </Tooltip>
          )}
          {menuState.selectedMenu === '공개 공지사항' && windowSize.innerWidth > 600 && (
            <Tooltip title='Home'>
              <IconButton 
                aria-label='home'
                component={RouterLink}
                to='/app'
                onClick={() => handleHomeClick()}
              >
                <ArrowBackIosIcon
                  className={classes.backiosIcon} 
                  
                  // style={{cursor: "pointer"}}
                />
              </IconButton>
            </Tooltip>
          )}
          {menuState.selectedMenu === 'Dashboard' || menuState.selectedMenu === '맴버 채팅' || menuState.selectedMenu === '공개 공지사항' ? (
            <Hidden xsDown>
              <Typography className={classes.headTitle}>
                {menuState.selectedMenu}
              </Typography>
            </Hidden>
          )
          :
          (
            <Typography className={classes.headTitle}>
            {menuState.selectedMenu}
            </Typography>
          )}
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small">
                    <SearchIcon className={classes.searchContent}/>
                  </SvgIcon>
                </InputAdornment>
              ),
              classes:{notchedOutline:classes.noBorder},
              className: classes.Searchinput
            }}
            onChange={handleQueryChange}
            placeholder="Search.."
            size='small'
            value={query}
            variant="outlined"
          />
        </Box>
        
        <Box display={'flex'} alignItems={'center'}>
          <Box ml={1} className={classes.themeBox}>
            {theme.theme === 'LIGHT' ? (
              <Tooltip title='Light Mode'>
                <IconButton
                  aria-label='Theme'
                  onClick={handleThemeClick}
                >
                  <FiSun className={classes.themeIcon}/>
                  </IconButton>
              </Tooltip>
            ):(
              <Tooltip title='Dark Mode'>
                <IconButton
                  aria-label='Theme'
                  onClick={handleThemeClick}
                >
                  <FiMoon className={classes.themeIcon}/>
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Hidden xsDown>
          <Box ml={1}>
            {/* <Tooltip title=''> */}
              {/* <IconButton
                aria-label='Theme'
                onClick={handleThemeClick}
              > */}
                {/* <Notifications /> */}
              {/* </IconButton> */}
            {/* </Tooltip> */}
          </Box>
          <Box ml={1}>
            {/* <Tooltip title=''> */}
              {/* <IconButton
                aria-label='Theme'
                onClick={handleThemeClick}
              > */}
                {/* <Messages /> */}
              {/* </IconButton> */}
            {/* </Tooltip> */}
          </Box>
        
        {/* <RouterLink to="/app"> */}
          {/* <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={handleHomeClick}
            style={{cursor: 'pointer'}}
          >
            <Image folder="topbar" filename="Code_BI_horizontal" width="262px" height="64px"/>
          </Box> */}
        {/* </RouterLink> */}
        {/* <Hidden mdDown>
          <Box mx={5} />
        </Hidden> */}
          

        {/* <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            Version
            {' '}
            {APP_VERSION}
          </Typography>
        </Hidden> */}
        {/* <Box flexGrow={1} />
        <Grid>
          <Link
            className={classes.link}
            component={RouterLink}
            to="/app"
            underline="none"
            variant="body2"
          >
            <Button className={classes.button} >
              SIGN UP
            </Button>
          </Link>
        </Grid> */}
        {/* <Box mx={1} />
        <Typography className={classes.topTextA}>
          |
        </ Typography>
        <Box mx={1} /> */}

        {/* 언어 선택 셀렉트 - 추후 활성화 */}
          {/* <Box ml={1}>
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              defaultValue="ENG"
            >
              <MenuItem value='ENG'>
                  <RouterLink to="/eng" >
                    <Flag className={classes.logo} lang="eng" />
                  </RouterLink>
              </MenuItem>
              <MenuItem value='KOR'>
                  <RouterLink to="/kor" >
                    <Flag className={classes.logo} lang="kor" />
                  </RouterLink>
              </MenuItem>
            </Select>
          </Box> */}
          </Hidden>
          <Hidden smUp>
            <AccountAvatar width={'36px'} height={'36px'} />
          </Hidden>
          {/* <Box className={classes.menuIcon}>
            <IconButton
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Box> */}
          {/* <Hidden xsDown>
            <Settings/>
          </Hidden> */}
        </Box>
        {/* <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app"
          underline="none"
          variant="body2"
        >
          Dashboard
        </Link> */}
        {/* <Hidden mdDown>
         <Box mx={5} />
        </Hidden> */}
      </Toolbar>
      {/* </Hidden> */}
    </AppBar>
    )
    }

    {/* 600px <= innerWidth < 960px 구간에서 채팅 로비 상태일때 표시되는 tppbar */}

    { menu.selectedMenu  === '채팅 로비' && windowSize.innerWidth > 600 && (
    <AppBar
      className={clsx(classes.root, className)}
      // color="light"
      {...rest}
    >
      {/* <Hidden mdDown> */}
      <Toolbar className={classes.toolbar} id="topbar">
        
        <Box className={classes.topLeft}>
          <Hidden smUp>
            {menuState.selectedMenu === 'Dashboard'  && (
              <Box
                // pt={'30px'}
                display="flex"
                justifyContent="center"
                flexDirection='column'
                style={{cursor:'pointer'}}
                onClick ={handleHomeClick}
              >
                <RouterLink 
                  to="/app"
                  style={{textDecoration: 'none'}}
                >
                  <CodeLogo />
                </RouterLink>
              </Box>
            )}
            {menuState.selectedMenu === '맴버 채팅'  && (
              <Box
                // pt={'30px'}
                display="flex"
                justifyContent="center"
                flexDirection='column'
                style={{cursor:'pointer'}}
                onClick ={handleHomeClick}
              >
                <RouterLink 
                  to="/app"
                  style={{textDecoration: 'none'}}
                >
                  <CodeLogo />
                </RouterLink>
              </Box>
            )}
          </Hidden>
          {menuState.selectedMenu !== 'Dashboard' && menuState.selectedMenu !== '맴버 채팅' && menuState.selectedMenu !== '' && (
            <Tooltip title='맴버 채팅'>
              <IconButton 
                aria-label='chat'
                component={RouterLink}
                to='/app/chat'
                onClick={() => handleHomeClick()}
              >
                <ArrowBackIosIcon
                  className={classes.backiosIcon} 
                  
                  // style={{cursor: "pointer"}}
                />
              </IconButton>
            </Tooltip>
          )}
          {menuState.selectedMenu === 'Dashboard' || menuState.selectedMenu === '맴버 채팅'  ? (
            <Hidden xsDown>
              <Typography className={classes.headTitle}>
                {menuState.selectedMenu}
              </Typography>
            </Hidden>
          )
          :
          (
            <Typography className={classes.headTitle}>
            {menuState.selectedMenu !== '채팅 로비' ? menuState.selectedMenu : '채팅방'}
            </Typography>
          )}
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small">
                    <SearchIcon className={classes.searchContent}/>
                  </SvgIcon>
                </InputAdornment>
              ),
              classes:{notchedOutline:classes.noBorder},
              className: classes.Searchinput
            }}
            onChange={handleQueryChange}
            placeholder="Search.."
            size='small'
            value={query}
            variant="outlined"
          />
        </Box>
        
        <Box display={'flex'} alignItems={'center'}>
          <Box ml={1} className={classes.themeBox}>
            {theme.theme === 'LIGHT' ? (
              <Tooltip title='Light Mode'>
                <IconButton
                  aria-label='Theme'
                  onClick={handleThemeClick}
                >
                  <FiSun className={classes.themeIcon}/>
                  </IconButton>
              </Tooltip>
            ):(
              <Tooltip title='Dark Mode'>
                <IconButton
                  aria-label='Theme'
                  onClick={handleThemeClick}
                >
                  <FiMoon className={classes.themeIcon}/>
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Hidden xsDown>
          <Box ml={1}>
            {/* <Tooltip title=''> */}
              {/* <IconButton
                aria-label='Theme'
                onClick={handleThemeClick}
              > */}
                {/* <Notifications /> */}
              {/* </IconButton> */}
            {/* </Tooltip> */}
          </Box>
          <Box ml={1}>
            {/* <Tooltip title=''> */}
              {/* <IconButton
                aria-label='Theme'
                onClick={handleThemeClick}
              > */}
                {/* <Messages /> */}
              {/* </IconButton> */}
            {/* </Tooltip> */}
          </Box>
        
        {/* <RouterLink to="/app"> */}
          {/* <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={handleHomeClick}
            style={{cursor: 'pointer'}}
          >
            <Image folder="topbar" filename="Code_BI_horizontal" width="262px" height="64px"/>
          </Box> */}
        {/* </RouterLink> */}
        {/* <Hidden mdDown>
          <Box mx={5} />
        </Hidden> */}
          

        {/* <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            Version
            {' '}
            {APP_VERSION}
          </Typography>
        </Hidden> */}
        {/* <Box flexGrow={1} />
        <Grid>
          <Link
            className={classes.link}
            component={RouterLink}
            to="/app"
            underline="none"
            variant="body2"
          >
            <Button className={classes.button} >
              SIGN UP
            </Button>
          </Link>
        </Grid> */}
        {/* <Box mx={1} />
        <Typography className={classes.topTextA}>
          |
        </ Typography>
        <Box mx={1} /> */}

        {/* 언어 선택 셀렉트 - 추후 활성화 */}
          {/* <Box ml={1}>
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              defaultValue="ENG"
            >
              <MenuItem value='ENG'>
                  <RouterLink to="/eng" >
                    <Flag className={classes.logo} lang="eng" />
                  </RouterLink>
              </MenuItem>
              <MenuItem value='KOR'>
                  <RouterLink to="/kor" >
                    <Flag className={classes.logo} lang="kor" />
                  </RouterLink>
              </MenuItem>
            </Select>
          </Box> */}
          </Hidden>
          <Hidden smUp>
            <AccountAvatar width={'36px'} height={'36px'} />
          </Hidden>
          {/* <Box className={classes.menuIcon}>
            <IconButton
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Box> */}
          {/* <Hidden xsDown>
            <Settings/>
          </Hidden> */}
        </Box>
        {/* <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app"
          underline="none"
          variant="body2"
        >
          Dashboard
        </Link> */}
        {/* <Hidden mdDown>
         <Box mx={5} />
        </Hidden> */}
      </Toolbar>
      {/* </Hidden> */}
    </AppBar>
    )
    }
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

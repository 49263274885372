import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Hidden,
  Link,
  Button,
  SvgIcon,
  Toolbar,
  makeStyles
} from "@material-ui/core";
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 11px',
    
  },
  topTextA: {
    fontFamily: 'Montserrat',
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '33.6px',
    color: '#FFFFFF',
    letterSpacing: 0,
    textAlign: 'center'
  },
  topTextB: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFFFFF',
    lineHeight: '21.6px',
    letterSpacing: 0,
    textAlign: 'center',
    // marginLeft: 10,
    // paddingTop: 8
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  const {settings} = useSettings();
  
  return (
    // <Box className={classes.logoContainer}>
      <SvgIcon {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 5C21 4.30685 20.6411 3.66311 20.0515 3.2987C19.4618 2.93429 18.7255 2.90116 18.1056 3.21115L10.5279 7H7C4.79086 7 3 8.79086 3 11C3 13.0571 4.55278 14.7514 6.55015 14.975L8.10264 19.6325C8.37487 20.4491 9.13915 21 10 21H11C12.1046 21 13 20.1046 13 19V16.2361L18.1056 18.7889C18.7256 19.0988 19.4618 19.0657 20.0515 18.7013C20.6411 18.3369 21 17.6932 21 17V5Z" stroke="#858EA1" stroke-width="2" stroke-linejoin="round"/>
        </svg>
        {/* <img src={'/static/icons/icon_solid_speakerPhone.svg'} width='24' title='Announce'/> */}
      </SvgIcon>
    // </Box>
  );
}

export default Logo;

import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useDispatch, useSelector } from 'src/store';
import { updateAccount } from 'src/slices/account';
import useAuth from 'src/hooks/useAuth';
import { ContactlessOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 50,
    width: 50,
    // marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const AccountAvatarDesktop = ({width, height}) => {
  const classes = useStyles();
  const history = useHistory();
  const account = useSelector((state) => state.account.value) // store 구독
  const { user } = useAuth();
  const ref = useRef(null);
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [avatar, setAvatar] = useState(user ? user.avatar ? user.avatar : '/static/images/avatars/avatar_null.svg' : '/static/images/avatars/avatar_null.svg');
  // console.log("topbar", user);
  const isMountedRef = useIsMountedRef();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    // try {
    //   handleClose();
    //   logout(user.uuid); // userid 계정의 active, lastactivity를 업데이트 한다.
    //   history.push('/');
    // } catch (err) {
    //   console.error(err);
    //   enqueueSnackbar('Unable to logout', {
    //     variant: 'error'
    //   });
    // }
    if(window.confirm('로그아웃 하시겠습니까?')) {
      logout(user.uuid);
      let url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`;
      // console.log('recChatUrl',chatUrl);
      window.location.href = url; // 로그인 화면을 로딩한다.
    }
  };
  
  const getAccount = useCallback( async () => {
    
    const response = await axios.get('/api/account/me');
    const { user } = response.data;
    setAvatar(user.avatar ? user.avatar : '/static/images/avatars/avatar_null.svg');
    // console.log('accountAvatar')
  },[isMountedRef, account.loginAccount])

  useEffect(() => {
    getAccount();
  },[getAccount])

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        // component={ButtonBase}
        // onClick={handleOpen}
        ref={ref}
      >
        <RouterLink
          to="/app/account" 
        >
          <Avatar
            alt="User"
            className={classes.avatar}
            src={avatar}
            style={{border:'solid 2px #4CB7D7', width: width, height: height}}
          />
        </RouterLink>
        {/* <Hidden smDown> */}
          {/* <Typography
            variant="h6"
            color="inherit"
          >
            {user.name}
          </Typography> */}
        {/* </Hidden> */}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to="/app/account"
        >
          계정정보
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          로그아웃
        </MenuItem>
      </Menu>
    </>
  );
}

export default AccountAvatarDesktop;

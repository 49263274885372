import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => { //state가 비어 있으면 빈 상태의 state가 사용된다.
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload; 

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload; 

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'UPDATEPROFILE': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'UPDATEPASSWORD': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'CHECKEMAIL': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'RESETPASSWORD': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  checkEmail: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();
  
  const login = async (userid, password) => {
    const response = await axios.post('/api/account/login', { userid, password });
    const { accessToken, user } = response.data;
    // accessToken 을 웹로컬스토리지에 저장한다.
    window.localStorage.setItem('accessToken', accessToken);
    // history.push('/app/report/dashboard/device');
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async (uuid) => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    const response = await axios.post('/api/account/logout', { uuid });

  };

  const register = async (userId, farmName, email, name, password, phone, role) => {
    // api를 호출하여 처리 결과를 반환한다.
    const response = await axios.post('/api/account/register', {
      userId,
      farmName,
      email,
      name,
      password,
      phone,
      role
    });
    const { accessToken, user } = response.data; // api가 반환한 값을 사용한다.

    // 로그인 처리를 해준다.
    login(user.userid, user.password);
    
    // reducer 안에 정의된 action을 실행한다.
    dispatch({
      type: 'REGISTER',
      payload: { // action 에 데이터를 보낸다.
        user
      }
    });

    // user data를 반환
    return user;
  };

  const updateProfile = async ( farmName, name, email, phone, role ) => {
    // api를 호출하여 처리 결과를 반환한다.
    const response = await axios.post('/api/account/modify', {
      farmName,
      name,
      email,
      phone,
      role
    });
    const { accessToken, user } = response.data; // api가 반환한 값을 사용한다.

    window.localStorage.setItem('accessToken', accessToken);


    // reducer 안에 정의된 action을 실행한다.    
    dispatch({
      type: 'UPDATEPROFILE',
      payload: { // action 에 데이터를 보낸다.
        user
      }
    });

    // user data를 반환
    return user;
  };

  const updatePassword = async (password) => {
    // api를 호출하여 처리 결과를 반환한다.
    const response = await axios.post('/api/account/changepassword', {
      password,
    });

    const { accessToken, user } = response.data; // api가 반환한 값을 사용한다.

    window.localStorage.setItem('accessToken', accessToken);

    // reducer 안에 정의된 action을 실행한다.
    dispatch({
      type: ' UPDATEPASSWORD',
      payload: { // action 에 데이터를 보낸다.
        user
      }
    });

    // user data를 반환
    return user;
  };

  const checkEmail = async (email) => {
    // api를 호출하여 처리 결과를 반환한다.
    const response = await axios.post('/api/account/emailcheck', {
      email,
    });

    const { user, message } = response.data; // api가 반환한 값을 사용한다.

    // window.localStorage.setItem('accessToken', accessToken);

    // reducer 안에 정의된 action을 실행한다.
    dispatch({
      type: 'CHECKEMAIL',
      payload: { // action 에 데이터를 보낸다.
        user
      }
    });

    // user data를 반환
    return response.data;
  };

  const resetPassword = async (uuid, password) => {

    // api를 호출하여 처리 결과를 반환한다.
    const response = await axios.post('/api/account/resetpassword', {
      uuid,
      password,
    });

    const { user, message } = response.data; // api가 반환한 값을 사용한다.

    // window.localStorage.setItem('accessToken', accessToken);

    // reducer 안에 정의된 action을 실행한다.
    dispatch({
      type: 'RESETPASSWORD',
      payload: { // action 에 데이터를 보낸다.
        user
      }
    });
    
    // user data를 반환
    return user;
  };

  // 가입된 정보로 나의 접속 정보를 초기화 한다.
  // 비동기 방식으로 처리해야할 부수적인 동작을 실행한다.
  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/account/me');
          const { user } = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        updateProfile,
        updatePassword,
        checkEmail,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
/* eslint-disable no-use-before-define */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Card,
  Drawer,
  Hidden,
  IconButton,
  InputAdornment ,
  List,
  ListSubheader,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Grid as GridIcon,
  BarChart as BarChartIcon,
  Radio as RadioIcon,
  Settings as SettingsIcon,
  Slack as SlackIcon,
} from 'react-feather';
import { 
  FiMessageSquare,
  FiUsers,
} from "react-icons/fi";
import { 
  HiOutlineSpeakerphone, 
  HiSpeakerphone  
} from 'react-icons/hi';
import { AiOutlineNotification } from "react-icons/ai";
import { BsColumnsGap } from "react-icons/bs";
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import AccountAvatarDesktop from 'src/components/AccountAvatarDesktop';
import CodeLogo from 'src/components/CodeLogo';
import DashboardLogo from 'src/components/DashboardLogo';
import ChatLogo from 'src/components/ChatLogo';
import SpeakerLogo from 'src/components/SpeakerLogo';
import SettingLogo from 'src/components/SettingLogo';

import Image from 'src/components/Image';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import FarmAddButton from 'src/components/FarmAddButton';
import useLocalStorage from 'src/hooks/useLocalStorage';

import { useDispatch, useSelector } from 'src/store';
import { updateFarm } from 'src/slices/farm';
import { updateFeed } from 'src/slices/feed';
import { updateMenu } from 'src/slices/menu';
import { updateWtank } from 'src/slices/wtank';
import { updateBreedinfo } from 'src/slices/breedinfo';
import { updateFeedaction } from 'src/slices/feedaction';
import { updateParameters } from 'src/slices/parameter';
import { updateNotificationlog } from 'src/slices/notificationlog';
import { updateNotification } from 'src/slices/notification';

const drawerWidth = "69px"; // 256px

// 스토어에 있는 selectedFarm 리듀서 상태 사용

// const farms = [
//   {
//     value: 'AD_Gochang',
//     label: 'AD GOCHANG',
//   },
//   {
//     value: 'Outside_Indonesia',
//     label: 'OUTSIDE INDONESIA',
//   },
// ];

// 모달 내부 좌표 위치
function rand() {
  return Math.round(Math.random() * 20) - 10;
  // return Math.round(Math.random() * 10) - 15;
}

function getFarmAddModalStyle() {
  const top = 50 + rand();
  // const left = 50 + rand();

  return {
    // top: `${top}%`,
    top: `calc( 50% - 325px )`,
    margin: "auto"
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}


const sections = [
  {
    // subheader: 'GENERAL', // GENERAL
    items: [
      {
        title: '', // Overview
        icon: DashboardLogo, // BsColumnsGap
        href: '/app/reports/dashboard/device'
        // href: '/app/reports/dashboard-farm'
      },
      {
        title: '', // Chat
        icon: ChatLogo, // FiMessageSquare
        href: '/app/chat/'
        // href: '/app/reports/dashboard-farm'
      },
      {
        title: '', // User List
        icon: SpeakerLogo, // AiOutlineNotification
        href: '/app/management/announces'
        // href: '/app/reports/dashboard-farm'
      },
      {
        title: '',  //Farm Settings
        icon: SettingLogo, // SettingsIcon
        href: '#'
      },
    ]
  },

  // {
  //   subheader: 'MAIN MENU',
  //   items: [
  //     {
  //       title: '',  // List Tank
  //       icon: GridIcon,
  //       href: '/app/management/tanks',
  //       // items: [
  //       //   {
  //       //     title: '고객리스트',
  //       //     href: '/app/management/customers'
  //       //   },
  //       //   // {
  //       //   //   title: '고객등록',
  //       //   //   href: '/app/management/customers/0/edit'
  //       //   // },
  //       //   {
  //       //     title: '고객등록 일괄등록',
  //       //     href: '/app/management/customers/import'
  //       //   }
  //       // ]
  //     },
  //     {
  //       title: 'Analysis Chart',
  //       icon: BarChartIcon,
  //       href: '/app/management/analysis',
  //     },
  //     {
  //       title: 'Notification',
  //       icon: RadioIcon,
  //       href: '/app/management/notification',
  //     },
      
  //   ]
  // },
 
];



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:theme.palette.background.default,
    height: '100%',
    
  },
  mobileDrawer: {
    backgroundColor:theme.palette.background.default,
    width: 68, // 256
    minHeight: 500,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  desktopDrawer: {
    backgroundColor:theme.palette.background.default,
    width: 68,
    minHeight: 500,
    borderRight: 'none',
    // top: 64,
    height: '100%',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',

  },
  desktopDrawerDashboard: {
    backgroundColor:theme.palette.background.default,
    width: 68,
    // minHeight: 700,
    borderRight: 'none',
    // top: 64,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    // [theme.breakpoints.down('sm')]: {
    //   height: 1190,
    // }

  },
  avatar: {
    cursor: 'pointer',
    width: 68,
    height: 68
  },
  navItem: {
    color: theme.palette.text.third
  },
  selectBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "8px",
    // height:"70px",
    width: "234px",
    marginTop: "32px"
  },
  select: {
    borderRadius: "8px",
    margin: "auto",
    bordor: "none",
    width: "234px",
    // height: "70px",
    boxShadow: "none",
  },
  selectText: {
    fontSize: '15px',
    color: "#FFFFFF"
  },
  optionText: {
    fontSize: '14px',
    color: "rgba(24, 24, 25, 0.6)"
  },
  subheader: {
    fontFamily: "Roboto",
    fontStyle:"normal",
    fontWeight: 500,
    fontSize: "12px",
    color: "#FFFFFF",
    lineHeight: "16.8px",
    marginTop: "42px"
  },
  copyrightBox: {
    height: "calc( 100% - 625px )",
    paddingTop: "calc( 100% - 32px)",

    // paddingTop: "calc( 100% - 200px )"
  },
  copyright: {
    fontFamily: "Roboto",
    fontStyle:"normal",
    fontWeight: 500,
    fontSize: "14px",
    color: "#B1B2B4",
    lineHeight: "19.6px",
    textAlign: "center"
  },
  formControl :{
    width: "100%",
  },
  paper: {
    position: 'absolute',
    width: 771,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("md")]:{
      width: "100%"
    }
  },
  footerIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 32,
    padding: '0px 11px ',
    // [theme.breakpoints.down('md')]: {
    //   position: 'absolute',
    //   bottom: 106,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   position: 'absolute',
    //   top: 1000,
    // }
  },
  footerIconMini: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 900,
    padding: '0px 11px ',
  },
  popover: {
    width: 200
  }

}));

const NavBar = ({ onMobileClose, openMobile, changeMenu }) => {
  const classes = useStyles();
  const { user, logout } = useAuth();
  const chat = useSelector((state) => state.chat) // store 구독
  const farm = useSelector((state) => state.farm.value); // store 구독
  // const feed = useSelector((state) => state.feed.value); // store 구독
  // const feedaction = useSelector((state) => state.feedaction.value); // store 구독
  const menu = useSelector((state) => state.menu.value); // store 구독
  // const wtank = useSelector((state) => state.wtank.value); // store 구독
  // const breedinfo = useSelector((state) => state.breedinfo.value); // store 구독
  const parameter = useSelector((state) => state.parameter.value); // store 구독
  const notificationlog = useSelector((state) => state.notificationlog.value); // store 구독
  const notification = useSelector((state) => state.notification.value); // store 구독

  const dispatch = useDispatch();
  // console.log("currentFarm : "+farm.selectedFarm);
  // console.log("chat", chat.activeThreadId);
  const path = chat.activeThreadId === null ? 'new' : chat.activeThreadId;
  // 측정정보 인터벌 인스턴스 Id를 로컬 storage에 저장한다. 
  const [intervalIdValue, setIntervalIdValue] = useLocalStorage("intervalIdValue",0);

  // 측정정보 인터벌 인스턴스를 삭제한다.
  clearInterval(intervalIdValue); 
  
  const isMountedRef = useIsMountedRef();
  const ref = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [ locationKeys, setLocationKeys ] = useState([]);
  const [isOpen, setOpen] = useState(false);
  // console.log("user",user);

  // 농장의 합계 정보 state
  const [totalInfo, setTotalInfo] = useState([]);

  const [currentTab, setCurrentTab] = useState('estimatedInfo');

  // 브라우저의 크기를 확인하여 메세지 리스트 요소의 높이를 계산한다.
  const getWindowSize = () => {
    const {innerWidth , innerHeight} = window;
    // let height = innerHeight - 201; // topbar(64) + paddingTop(42) + paddingBottom(42) + titleGridHeight(53)
    return {innerWidth, innerHeight};
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  //navItem 항목을 설정한다.
  const sections = [
    {
      // subheader: 'GENERAL', // GENERAL
      items: [
        {
          title: 'dashboard', // Overview
          icon: BsColumnsGap, // BsColumnsGap , DashboardLogo
          href: '/app/reports/dashboard/device'
        },
        {
          title: '', // Chat
          icon: FiMessageSquare, // FiMessageSquare , ChatLogo
          href: `/app/chat/${path}`
        },
        {
          title: '', // announce
          icon: AiOutlineNotification, // AiOutlineNotification , SpeakerLogo
          href: '/app/management/announces'
        },
        {
          title: 'setting',  //Farm Settings
          icon: SettingsIcon, // SettingsIcon , SettingLogo
          href: ''
        },
      ]
    },
  ]

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogoClick = () => {
    let currentMenu = {
      ...menu,
      selectedMenu: "Dashboard",
      path: '/app/reports/dashboard/device',
      lastMenu: menu.selectedMenu,
      lastMenuPath: menu.path
    }
    dispatch(updateMenu(currentMenu));
    // let homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/app/reports/dashboard/device`;
    
    // // 채팅 메세지 리랜더링 방지를 위해 챗팅방을 로드할때 브라우저를 새로고침 해준다.
    // window.location.href = homeUrl;
  }

  // 농장 변경
  const farmChange = async (e) => {
    // console.log("farmChange!", e.target.value);
    // console.log("intervalIdValue",intervalIdValue);
    // 인터벌 인스턴스를 삭제한다.
    clearInterval(intervalIdValue); 
    
    let selectedFarmName = e.target.value;

    // const responseFeed = await axios.get('/api/feed/count',{params: {farmName: selectedFarmName}});
    const responseFarm = await axios.get('/api/farm/search', {params: {farmName: selectedFarmName}});
    // const responseWtank = await axios.get('/api/wtank/count',{params: {farmName: selectedFarmName}});
    // const responseParameter = await axios.get('/api/parameter/count',{params: {farmName: selectedFarmName, wtank: wtank.selectedWtankItem === undefined ? 0 : wtank.selectedWtankItem['number']}});
    // const responseNotificationlog = await axios.get('/api/notificationlog/allcount',{params: {farmName: farm.selectedFarm}}); // 농장에 등록된 모든 알람로그 데이터를 가져온다.
    // const responseNotification = await axios.get('/api/notification/allcount',{params: {farmName: farm.selectedFarm}}); // 농장에 등록된 모든 알람종류 데이터를 가져온다.

    let selectedFarmItem = responseFarm.data;

    let currentFarm = {
      ...farm,
      // totalWtank: responseWtank.data['count'], // 총수조 수 업데이트
      selectedFarm : selectedFarmName,
      selectedFarmItem: selectedFarmItem,
    };
    
    dispatch(updateFarm(currentFarm)); // reduce action 실행
    
    // let currentWtank = {
    //   ...wtank,
    //   selectedWtank : "default",
    //   selectedWtankInfo:{
    //     number:0, // 선택된 수조번호로 초기화 한다.
    //     area: 0,
    //     length: 0,
    //     width: 0,
    //     depth: 0,
    //     breedinfoId: null,
    //     position: "",
    //     inputDate: "",
    //     individuals: 0,
    //     totalPeriod: 0,
    //     currentPeriod: 0,
    //   },
    //   estimatedInfo: {},
    //   estimatedInfoData: {},
    //   selectedWtankItem: undefined,
    //   wtankData: responseWtank.data['rows'],
    //   wtankData: {},
    // };
  
  // dispatch(updateWtank(currentWtank)); // reduce action 실행

    // let currentBreedinfo = {
    //   ...breedinfo,
    //   selectedBreedinfo: "default",
    //   selectedBreedinfoInfo:{
    //     id: "",
    //     farmName: "",
    //     name: "",
    //     position: "",
    //     inputDate: "",
    //     individuals: 0,
    //     shipmentTarget: "",
    //     totalPeriod: 0,
    //     currentPeriod: 0,
    //     },
    //     breedinfoData: {},
    // };
    
    // dispatch(updateBreedinfo(currentBreedinfo)); // reduce action 실행

    // 급이이력 store state를 업데이트 한다.
    // let currentFeedaction = {
    //   ...feedaction,

    //   feedactionData: [],
    // }
    // dispatch(updateFeedaction(currentFeedaction));

    // 매개변수 store state를 업데이트 한다.
    let currentParameter = {
        ...parameter,
        selectedParameter: "default",
        parameterData : [],
        parameterType: []
      }
      dispatch(updateParameters(currentParameter));

    // 사료정보 store state를 업데이트 한다.
    // let currentfeed = {
    //   ...feed,
    //   feedData : responseFeed.data['rows']
    // }
    // dispatch(updateFeed(currentfeed));

    // notificationlog(알람로그) state를 설정 한다. 
    let currentNotificationlog = {
      ...notificationlog,
      selectedNotificationlogData : {},
      currentNotificationlog : [],
      notificationlogData : []
    }

    // notificationlog(알람로그) store state를 업데이트 한다. 
    dispatch(updateNotificationlog(currentNotificationlog));

    // notification(알람목록) state를 설정 한다. 
    let currentNotification = {
      ...notification,
      currentNotification : [],
      notificationData : [],
    }

    // notification(알람목록) store state를 업데이트 한다. 
    dispatch(updateNotification(currentNotification));

    // console.log("farmChange",farm);
  }

  // 메뉴 변경
  const menuChange = (e) => {
    let currentMenu = {
      ...menu,
      selectedMenu : e.target.innerHTML,
    }
    dispatch(updateMenu(currentMenu));
  }

  //로그아웃
  const handleClickLogout = () => {
    if(window.confirm('로그아웃 하시겠습니까?')) {
      logout(user.uuid);
      let url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`;
      // console.log('recChatUrl',chatUrl);
      window.location.href = url; // 로그인 화면을 로딩한다.
    }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // 윈도우 크기가 변경되면 다시 렌더링 해준다.
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [window.innerWidth]);

  // 브라우저의 뒤로가기 버튼 이벤트를 처리한다.
  // useEffect(() => {
  //   return history.listen(location => {
  //     console.log('navigation button click', location);
  //     if (history.action === 'PUSH') {
  //       setLocationKeys([ location.key ])
  //     }
  
  //     if (history.action === 'POP') {
  //       if (locationKeys[1] === location.key) {
  //         setLocationKeys(([ _, ...keys ]) => keys)
  
  //         // Handle forward event
  //         console.log('forward click', location)
  
  //       } else {
  //         setLocationKeys((keys) => [ location.key, ...keys ])
  
  //         // Handle back event
  //         console.log('back click', location)
  
  //       }
  //     }
  //   })
  // }, [locationKeys]);

  var farms = [];

  const getItems = useCallback(async () => {
    try {
      // const response = await axios.get('/api/wmd/monthly');
      // farmName을 key로 하여 데이터를 가져온다.
      const response = await axios.get('/api/account/me');
      const { user } = response.data;
      const responseFarm = await axios.get('/api/farm/list');
      const responseWtank = await axios.get('/api/wtank/count',{params: {farmName: farm.selectedFarm}});
      // const responseWtankEstimatedInfo = await axios.get('/api/wtank/estimatedinfo',{params: {farmName: farm.selectedFarm, wtank: wtank.selectedWtank}});
      const responseNotification = await axios.get('/api/notification/allcount',{params: {farmName: farm.selectedFarm}});
      
      // const selectedWtankEstimatedInfo = responseWtankEstimatedInfo.data['result'];
      // console.log("NavItem", intervalIdValue);
      var selectedFarmItem = {};
      
      // console.log("farmList",responseFarm.data);

      // let farms =[];

      if (isMountedRef.current) {    
        
        // 인터벌 인스턴스를 삭제한다.
        clearInterval(intervalIdValue); 

        const farmData = responseFarm.data['items'];
        // console.log("farmData", farmData);
        // console.log("farms", farms);

        Object.keys(farmData).forEach((key) => {
          // console.log(farmData[key]);
          let farmItem = farmData[key];
          let labelArray = farmItem.farmname.split('_');
          let label = labelArray[0].toUpperCase()+" "+labelArray[1].toUpperCase();
          farms.push({
            value: farmItem.farmname,
            label: label,
          })
          if(farm.selectedFarm === undefined){ // 선택된 농장이 없을 경우
            if(farmItem.farmname === user.farmname){
              // console.log("selectedFarmItem", farmItem);
              selectedFarmItem = farmItem; //선택된 농장의 이름과 동일한 데이터를 farm store에 넣는다.
            }
          } else {
            if(farmItem.farmname === farm.selectedFarm){
              // console.log("selectedFarmItem", farmItem);
              selectedFarmItem = farmItem; 
            }
          }
          
        })

        // farm(농장 정보) state를 설정 한다.
        if(farm.selectedFarm === ""){
          let currentFarmState = {
            ...farm,
            selectedFarm: user.farmname, 
            // totalWtank: responseWtank.data['count'], // 총수조 수 업데이트,
            selectedFarmItem: selectedFarmItem,
            farmData : farmData,
            farmSelectList : farms,
          }    
          // farm(농장 정보) store state를 업데이트 한다. 
          dispatch(updateFarm(currentFarmState));
        }else{
          let currentFarmState = {
            ...farm,
            selectedFarm: farm.selectedFarm,
            // totalWtank: responseWtank.data['count'], // 총수조 수 업데이트,
            selectedFarmItem: selectedFarmItem,
            farmData : farmData,
            farmSelectList : farms,
          }
          // farm(농장 정보) store state를 업데이트 한다. 
          dispatch(updateFarm(currentFarmState));
        }
          
        // 수조 정보를 초기화 한다.
        // let currentWtank = {
        //   ...wtank,
        //   selectedWtank : "default",
        //   selectedWtankInfo:{
        //     number:0, // 선택된 수조번호로 초기화 한다.
        //     area: 0,
        //     length: 0,
        //     width: 0,
        //     depth: 0,
        //     breedinfoId: null,
        //     position: "",
        //     inputDate: "",
        //     individuals: 0,
        //     totalPeriod: 0,
        //     currentPeriod: 0,
        //   },
        //   estimatedInfo: {},
        //   estimatedInfoData: {},
        //   selectedWtankItem: undefined,
        //   wtankData : responseWtank.data['rows']
        // }
        // dispatch(updateWtank(currentWtank));

        // notification(알람목록) state를 설정 한다. 
        let currentNotification = {
          ...notification,
          currentNotification : responseNotification.data['count'],
          notificationData : responseNotification.data['rows']
        }

        // notification(알람목록) store state를 업데이트 한다. 
        dispatch(updateNotification(currentNotification));

        // }
        // console.log("getItem",farm)
        // console.log("farm", farm);
      }
     
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef, farm.selectedFarm ]);
  // }, []);

  useEffect(() => {
    // getItems();
  }, [getItems]);

  function renderNavItems({
    items,
    pathname,
    depth = 0,
    changeMenu,
    currentFarm = user.farmname
  }) {
    // console.log(items);
    return (
      <List disablePadding className={classes.navItem}>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, changeMenu, currentFarm }),
          []
        )}
      </List>
    );
  }

  // 농장 select 요소를 반환한다.
  function renderFarmSelect(farmSelectList) {
    // console.log(farmSelectList)
    
    if(farmSelectList){
      let farmSelect = [<option key="default" value="default" className={classes.optionText}>농장 선택</option>]
      farmSelect.push(
        farmSelectList.map((option) => (
          <option key={option.value} value={option.value} className={classes.optionText}>
            {option.label}
          </option>
        ))
      )
      return (
        farmSelect
      );
    }
  }

  function reduceChildRoutes({
    acc,
    item,
    pathname,
    depth,
  }) {
    // const key = item.title + depth;
    const key = item.href + depth;
  
    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
          className={classes.navItem}
          currentFarm={user.farmname}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          className={classes.navItem}
          currentFarm={user.farmname}
        />
      );
    }
  
    return acc;
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp> */}
          <Box
            pt={'30px'}
            display="flex"
            justifyContent="center"
            flexDirection='column'
            onClick={handleLogoClick}
            // style={{cursor: 'pointer', marginBottom: 30}}
          >
            <RouterLink 
              to="/app"
              style={{textDecoration: 'none', marginBottom: 30}}
            >
            <Box>
              <CodeLogo/>
            </Box>
              
            </RouterLink>
            <AccountAvatarDesktop width={'50px'} height={'50px'}  />            
          </Box>
        {/* </Hidden> */}
        {/* <Box 
          dispaly="flex" 
          justifyContent="center"
        >
          <Box
            className={classes.selectBox}
            mx={1}
          >
          <TextField
              className={classes.select}
              fullWidth
              id="role"
              name="role"
              margin="normal"
              select
              // label="Role"
              value={farm.selectedFarm} // 계정 정보에 있는 farmname을 기본값으로 설정한다.
              onChange={(event) => farmChange(event)}
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Image alt={'avatar'} folder={'nav'} filename={'avatar'} width={'32px'} height={'32px'} /> 
                    </Box>
                  </InputAdornment>
                ),
                className: classes.selectText // select 상자안의 text style
              }}
              // helperText="Please select your role"
            >
              {renderFarmSelect(farm.farmSelectList)}
            </TextField>
          </Box>
        </Box>
        <Box mx={1} mt={"18px"}>
          <FarmAddButton />
        </Box> */}
        
        <Box display={'flex'} justifyContent={'center'} p={1} pt={2}>
          {sections.map((section) => (
            <List
              key={'General'}
              // subheader={(
              //   <ListSubheader
              //     disableGutters
              //     disableSticky
              //     className={classes.subheader}
              //   >
              //     {section.subheader}
              //   </ListSubheader>
              // )}
            >
              {renderNavItems({
                menuChange,
                items: section.items,
                pathname: location.pathname,
                currentfarm: user.farmname
              })}
            </List>
          ))}
        </Box>
        <Box flexGrow={1}/>
        <Box className={windowSize.innerWidth > 600 && windowSize.innerWidth < 820 ? classes.footerIcon : classes.footerIcon}>
          <Tooltip title='로그아웃'>
            <IconButton
              aria-label='logout'
              onClick={handleClickLogout}
            >
              <Image filename="logout" folder="icons" width="20" height="20" color="#4AC1E0"/>
            </IconButton>
          </Tooltip>
          <Box ref={ref}>
            <Tooltip title='확장'>
              <IconButton
                aria-label='foldMenu'
                onClick={handleOpen}
              >
                <Image filename="foldMenu" folder="icons" width="20" height="20" color="#4AC1E0"/>
              </IconButton>
            </Tooltip>
            <Menu
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              keepMounted
              PaperProps={{ className: classes.popover }}
              getContentAnchorEl={null}
              anchorEl={ref.current}
              open={isOpen}
            >
              <MenuItem
                // component={RouterLink}
                // to="/app/account"
              >
                준비중입니다.
              </MenuItem>
            </Menu>
          </Box>
          
          
        </Box>
        
        {/* <Divider /> */}
        {/* <Box className={classes.copyrightBox}>
          <Box >
            <Typography className={classes.copyright}>
              © 2021 AD. All rights reserved.
            </Typography>
          </Box>
        </Box> */}
        
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
    {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
      <Hidden smUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              borderRight: 'none',
            },
            '& .MuiDrawer-paperAnchorDockedLeft' : {
              borderRight: 'none',
            },
          }}
          anchor="left"
          classes={{ paper: menu.selectedMenu === 'Dashboard' ? classes.desktopDrawerDashboard : classes.desktopDrawer }}
          onClose={onMobileClose}
          // open={openMobile}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* </PerfectScrollbar> */}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

import React from 'react';

const Flag = (props) => {
  let src = "/static/images/"+props.lang+".svg"
  return (
    <img
      alt="flag"
      src={src}
      {...props}
    />
  );
}

export default Flag;
